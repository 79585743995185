import React from 'react';
import Input from '../shared/Input';
import Select from '../shared/Select';
import Button from '../shared/Button';
import { PRICING_UNITS } from '../../utils/pricing';
import { PricingItemFormData } from '../../types/pricing';

interface PricingItemFormProps {
  data: PricingItemFormData;
  onChange: (data: PricingItemFormData) => void;
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
  loading?: boolean;
}

const PricingItemForm: React.FC<PricingItemFormProps> = ({
  data,
  onChange,
  onSubmit,
  onCancel,
  loading
}) => {
  const categories = [
    { value: 'flooring', label: 'Flooring' },
    { value: 'tools', label: 'Tools' },
    { value: 'accessories', label: 'Accessories' }
  ];

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <Input
        label="Name"
        value={data.name}
        onChange={(e) => onChange({ ...data, name: e.target.value })}
        required
      />

      <Input
        label="Price"
        type="number"
        step="0.01"
        min="0"
        value={data.price}
        onChange={(e) => onChange({ ...data, price: parseFloat(e.target.value) })}
        required
      />

      <Select
        label="Unit"
        value={data.unit}
        onChange={(e) => onChange({ ...data, unit: e.target.value })}
        options={[
          { value: '', label: 'Select a unit' },
          ...PRICING_UNITS
        ]}
        required
      />

      {data.type === 'material' && (
        <Select
          label="Category"
          value={data.category}
          onChange={(e) => onChange({ ...data, category: e.target.value })}
          options={[
            { value: '', label: 'Select a category' },
            ...categories
          ]}
        />
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          value={data.description}
          onChange={(e) => onChange({ ...data, description: e.target.value })}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div className="flex justify-end space-x-4 pt-6">
        <Button
          type="button"
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          loading={loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default PricingItemForm;