import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getPricingItems, deletePricingItem } from '../../lib/api/pricing';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PricingItemsTable from './PricingItemsTable';
import type { PricingItem } from '../../types/pricing';

interface MaterialsPricingTableProps {
  onEdit: (id: string) => void;
}

const MaterialsPricingTable: React.FC<MaterialsPricingTableProps> = ({ onEdit }) => {
  const { user } = useAuth();
  const [items, setItems] = useState<PricingItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);

  useEffect(() => {
    fetchItems();
  }, [user]);

  const fetchItems = async () => {
    if (!user) return;

    try {
      setLoading(true);
      setError(null);
      const data = await getPricingItems(user.id, 'material');
      setItems(data);
    } catch (err) {
      console.error('Error fetching pricing items:', err);
      setError('Failed to load pricing items');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setDeletingId(id);
      setError(null);
      await deletePricingItem(id);
      setItems(prevItems => prevItems.filter(item => item.id !== id));
    } catch (err) {
      console.error('Error deleting pricing item:', err);
      setError('Failed to delete item');
    } finally {
      setDeletingId(null);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <PricingItemsTable
      items={items}
      onEdit={onEdit}
      onDelete={handleDelete}
      deletingId={deletingId}
      showCategory={true}
    />
  );
};

export default MaterialsPricingTable;