import React from 'react';
import { Clock, Ruler, Grid, FileText, ChevronRight } from 'lucide-react';

interface WorkflowStepProps {
  step: {
    title: string;
    description: string;
    color: string;
    features: string[];
  };
  index: number;
}

const WorkflowStep: React.FC<WorkflowStepProps> = ({ step, index }) => {
  const getIcon = (index: number) => {
    switch (index) {
      case 0:
        return Clock;
      case 1:
        return Ruler;
      case 2:
        return Grid;
      case 3:
        return FileText;
      default:
        return Clock;
    }
  };

  const Icon = getIcon(index);

  return (
    <div className={`py-24 sm:py-32 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <div className="flex flex-col justify-center">
            <div className={`flex h-12 w-12 items-center justify-center rounded-xl ${step.color} mb-8`}>
              <Icon className="h-6 w-6 text-white" />
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-6">
              {step.title}
            </h2>
            <p className="text-lg leading-8 text-gray-600 mb-8">
              {step.description}
            </p>
            <ul className="space-y-3">
              {step.features.map((feature, featureIndex) => (
                <li key={featureIndex} className="flex items-center gap-3">
                  <div className={`flex h-6 w-6 items-center justify-center rounded-full ${step.color}`}>
                    <ChevronRight className="h-4 w-4 text-white" />
                  </div>
                  <span className="text-gray-700">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowStep;