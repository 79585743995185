import React, { useState } from 'react';
import { Users } from 'lucide-react';
import Button from './Button';

interface FloatingContactButtonProps {
  onClick: () => void;
}

const FloatingContactButton: React.FC<FloatingContactButtonProps> = ({ onClick }) => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isContactPickerSupported = 'contacts' in navigator && 'ContactsManager' in window;

  const handleClick = async () => {
    if (!isContactPickerSupported) {
      alert('Contact picker is not supported in your browser. Please use Chrome on Android.');
      return;
    }

    if (hasPermission === null || hasPermission === false) {
      try {
        // Request permission through the Contact Picker API
        // @ts-ignore - ContactsManager API types not yet in TypeScript
        await navigator.contacts.select(['name'], { multiple: false });
        setHasPermission(true);
      } catch (err) {
        if (err instanceof Error && err.name === 'NotAllowedError') {
          setHasPermission(false);
          alert('Please allow access to contacts to use this feature.');
          return;
        }
      }
    }

    onClick();
  };

  // Only show on mobile devices with Contact Picker support
  if (!isMobile || !isContactPickerSupported) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <Button
        onClick={handleClick}
        variant="primary"
        size="lg"
        className="rounded-full shadow-lg !p-4 bg-indigo-600 hover:bg-indigo-700 transition-colors"
      >
        <Users className="h-6 w-6" />
      </Button>
    </div>
  );
};

export default FloatingContactButton;