import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getClients } from '../../lib/api/clients';
import AppointmentForm from '../../components/appointments/AppointmentForm';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import Button from '../../components/shared/Button';
import ClientModal from '../../components/clients/ClientModal';
import BottomNavigation from '../../components/shared/BottomNavigation';
import type { Client } from '../../types/clients';

const NewAppointment: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showNewClientModal, setShowNewClientModal] = useState(false);

  useEffect(() => {
    fetchClients();
  }, [user]);

  const fetchClients = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const data = await getClients(user.id);
      setClients(data);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError('Failed to load clients');
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    navigate('/appointments');
  };

  const handleNewClient = (newClient: Client) => {
    setClients(prevClients => [...prevClients, newClient]);
    setShowNewClientModal(false);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  if (clients.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center py-12">
            <h3 className="mt-2 text-sm font-medium text-gray-900">No clients found</h3>
            <p className="mt-1 text-sm text-gray-500">
              You need to add at least one client before creating an appointment.
            </p>
            <div className="mt-6">
              <Button
                variant="primary"
                icon={Plus}
                onClick={() => setShowNewClientModal(true)}
              >
                Add Client
              </Button>
            </div>
          </div>
        </div>

        {showNewClientModal && (
          <ClientModal
            userId={user?.id}
            onClose={() => setShowNewClientModal(false)}
            onSuccess={handleNewClient}
          />
        )}
        
        <BottomNavigation />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">Create New Appointment</h1>
          <Button
            variant="secondary"
            icon={Plus}
            onClick={() => setShowNewClientModal(true)}
          >
            Add New Client
          </Button>
        </div>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <AppointmentForm
            clients={clients}
            onSuccess={handleSuccess}
          />
        </div>
      </div>

      {showNewClientModal && (
        <ClientModal
          userId={user?.id}
          onClose={() => setShowNewClientModal(false)}
          onSuccess={handleNewClient}
        />
      )}
      
      <BottomNavigation />
    </div>
  );
};

export default NewAppointment;