import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import EstimatePreview from '../../components/estimates/EstimatePreview';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import DownloadButton from '../../components/estimates/DownloadButton';
import { parseMeasurements } from '../../utils/measurements';

const PublicEstimateView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [estimate, setEstimate] = useState<any>(null);
  const [businessDetails, setBusinessDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchEstimate();
  }, [id]);

  useEffect(() => {
    // Update document title when estimate is loaded
    if (estimate) {
      document.title = `Estimate #${estimate.estimate_number} | EstimadoPro`;
    }
  }, [estimate]);

  const fetchEstimate = async () => {
    if (!id) return;

    try {
      setLoading(true);
      setError(null);

      const { data: estimateData, error: estimateError } = await supabase
        .from('estimates')
        .select(`
          *,
          client:clients!estimates_client_id_fkey (
            id,
            name,
            company,
            email,
            phone,
            street_address,
            city,
            state,
            zip,
            country
          ),
          user:profiles!estimates_user_id_fkey (
            business_name,
            business_email,
            business_phone,
            business_address,
            business_city,
            business_state,
            business_zip,
            business_website,
            business_logo,
            theme_color
          )
        `)
        .eq('id', id)
        .single();

      if (estimateError) throw new Error('This estimate is no longer available');
      if (!estimateData) throw new Error('Estimate not found');

      const businessDetails = {
        business_name: estimateData.user?.business_name,
        business_email: estimateData.user?.business_email,
        business_phone: estimateData.user?.business_phone,
        business_address: estimateData.user?.business_address,
        business_city: estimateData.user?.business_city,
        business_state: estimateData.user?.business_state,
        business_zip: estimateData.user?.business_zip,
        business_website: estimateData.user?.business_website,
        business_logo: estimateData.user?.business_logo,
        theme_color: estimateData.user?.theme_color || 'bg-indigo-600'
      };

      const parsedEstimate = {
        ...estimateData,
        items: typeof estimateData.items === 'string' 
          ? JSON.parse(estimateData.items) 
          : estimateData.items,
        measurements: parseMeasurements(estimateData.measurements)
      };

      setEstimate(parsedEstimate);
      setBusinessDetails(businessDetails);
    } catch (err) {
      console.error('Error:', err);
      setError(err instanceof Error ? err.message : 'This estimate is no longer available');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <ErrorMessage message={error} />
      </div>
    );
  }

  if (!estimate) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <ErrorMessage message="Estimate not found" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 sm:py-8">
      <div className="max-w-4xl mx-auto px-0 sm:px-6 lg:px-8">
        <div className="mb-4 sm:mb-6 px-4 sm:px-0">
          <DownloadButton
            estimateNumber={estimate.estimate_number}
            className="w-full sm:w-auto"
            themeColor={businessDetails.theme_color}
          />
        </div>

        <EstimatePreview
          estimate={estimate}
          businessDetails={businessDetails}
          themeColor={businessDetails.theme_color}
        />
      </div>
    </div>
  );
};

export default PublicEstimateView;