import React, { useState } from 'react';
import { Users } from 'lucide-react';
import Button from './Button';

interface ContactPickerProps {
  onContactSelect: (contact: {
    name: string;
    email?: string;
    phone?: string;
    company?: string;
  }) => void;
  className?: string;
}

const ContactPicker: React.FC<ContactPickerProps> = ({ onContactSelect, className = '' }) => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isContactPickerSupported = 'contacts' in navigator && 'ContactsManager' in window;

  const requestContactPermission = async () => {
    try {
      // Request permission through the Contact Picker API
      // @ts-ignore - ContactsManager API types not yet in TypeScript
      const contacts = await navigator.contacts.select(
        ['name', 'email', 'tel', 'organization'],
        { multiple: false }
      );
      
      // If we get here, permission was granted
      setHasPermission(true);
      
      if (contacts.length > 0) {
        const contact = contacts[0];
        onContactSelect({
          name: contact.name?.[0] || '',
          email: contact.email?.[0] || '',
          phone: contact.tel?.[0] || '',
          company: contact.organization?.[0] || ''
        });
      }
    } catch (err) {
      console.error('Error requesting contact permission:', err);
      if (err instanceof Error) {
        if (err.name === 'NotAllowedError') {
          setHasPermission(false);
        } else {
          alert('Error accessing contacts. Please try again or enter contact details manually.');
        }
      }
    }
  };

  const handleSelectContact = async () => {
    if (!isContactPickerSupported) {
      alert('Contact picker is not supported in your browser. Please use Chrome on Android or manually enter the contact details.');
      return;
    }

    if (hasPermission === null || hasPermission === false) {
      await requestContactPermission();
    } else {
      try {
        // @ts-ignore - ContactsManager API types not yet in TypeScript
        const contacts = await navigator.contacts.select(
          ['name', 'email', 'tel', 'organization'],
          { multiple: false }
        );

        if (contacts.length > 0) {
          const contact = contacts[0];
          onContactSelect({
            name: contact.name?.[0] || '',
            email: contact.email?.[0] || '',
            phone: contact.tel?.[0] || '',
            company: contact.organization?.[0] || ''
          });
        }
      } catch (err) {
        console.error('Error selecting contact:', err);
        if (err instanceof Error && err.name === 'NotAllowedError') {
          setHasPermission(false);
        }
      }
    }
  };

  // Only show on mobile devices
  if (!isMobile) {
    return null;
  }

  return (
    <Button
      onClick={handleSelectContact}
      variant="secondary"
      icon={Users}
      className={`w-full sm:w-auto ${className}`}
    >
      {hasPermission === false ? 'Enable Contact Access' : 'Import from Contacts'}
      {!isContactPickerSupported && <span className="text-xs ml-1">(Chrome only)</span>}
    </Button>
  );
};

export default ContactPicker;