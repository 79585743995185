import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        'pricing-table-id': string;
        'publishable-key': string;
        'client-reference-id'?: string;
        'customer-email'?: string;
        'success-url'?: string;
        'cancel-url'?: string;
      };
    }
  }
}

interface StripePricingTableProps {
  className?: string;
}

const StripePricingTable: React.FC<StripePricingTableProps> = ({ className = '' }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const baseUrl = window.location.origin;

  useEffect(() => {
    // Load Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Listen for Stripe events
    const handleStripeMessage = (event: MessageEvent) => {
      if (event.data.type === 'checkout-completed') {
        // Redirect to signup if not logged in
        if (!user) {
          navigate('/signup');
        }
      }
    };

    window.addEventListener('message', handleStripeMessage);
    return () => window.removeEventListener('message', handleStripeMessage);
  }, [user, navigate]);

  return (
    <div className={className}>
      <stripe-pricing-table
        pricing-table-id="prctbl_1QSLT8A9sF72t01M1spsEB6m"
        publishable-key="pk_live_51PvPa0A9sF72t01M2hGcJraYHRAoGpXXJRutwuLdLITka5mlRzUklWsUk19a0LvB2PeiprSqSWsiPEAqMpJ1rHyg007nrbfSEI"
        client-reference-id={user?.id}
        customer-email={user?.email}
        success-url={`${baseUrl}/signup?session_id={CHECKOUT_SESSION_ID}`}
        cancel-url={`${baseUrl}/?upgrade=true#pricing`}
      />
    </div>
  );
};

export default StripePricingTable;