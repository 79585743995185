import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FileText, Edit, Eye, AlertCircle, Printer } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getEstimate, updateEstimateStatus } from '../../lib/api/estimates';
import { getProfile } from '../../lib/api/profiles';
import { printElement } from '../../utils/print';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import Button from '../../components/shared/Button';
import EstimatePreview from '../../components/estimates/EstimatePreview';
import StatusChanger from '../../components/estimates/StatusChanger';
import FloatingShareButton from '../../components/shared/FloatingShareButton';
import ColorPicker from '../../components/shared/ColorPicker';
import { useTheme } from '../../contexts/ThemeContext';

const ViewEstimate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'preview' | 'edit' | 'status'>('preview');
  const [estimate, setEstimate] = useState<any>(null);
  const [businessDetails, setBusinessDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [printing, setPrinting] = useState(false);
  const { themeColor } = useTheme();

  useEffect(() => {
    fetchData();
  }, [id, user]);

  const fetchData = async () => {
    if (!user || !id) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const [estimateData, profileData] = await Promise.all([
        getEstimate(id),
        getProfile(user.id)
      ]);

      if (!estimateData) {
        throw new Error('Estimate not found');
      }

      setEstimate(estimateData);
      setBusinessDetails(profileData);
    } catch (err) {
      console.error('Error fetching estimate:', err);
      setError('Failed to load estimate');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (newStatus: 'draft' | 'pending' | 'approved' | 'rejected') => {
    if (!id || !estimate) return;

    try {
      setUpdatingStatus(true);
      setError(null);

      const updatedEstimate = await updateEstimateStatus(id, newStatus);
      setEstimate({ ...estimate, ...updatedEstimate });
      setActiveTab('preview');
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status');
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleTabChange = (tab: 'preview' | 'edit' | 'status') => {
    if (tab === 'edit') {
      navigate(`/estimates/edit/${id}`);
    } else {
      setActiveTab(tab);
    }
  };

  const handleBack = () => {
    navigate('/estimates');
  };

  const handlePrint = async () => {
    try {
      setPrinting(true);
      await printElement('estimate-content');
    } catch (err) {
      console.error('Error printing:', err);
    } finally {
      setPrinting(false);
    }
  };

  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/e/${id}`;
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!estimate) return <ErrorMessage message="Estimate not found" />;

  const tabs = [
    { id: 'preview', label: 'Preview', icon: Eye },
    { id: 'edit', label: 'Edit', icon: Edit },
    { id: 'status', label: 'Status', icon: AlertCircle }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mb-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="flex items-center">
            <button
              onClick={handleBack}
              className="mr-4 text-gray-400 hover:text-gray-500"
            >
              <FileText className="h-6 w-6" />
            </button>
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                Estimate #{estimate.estimate_number}
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                {estimate.client?.company} - {estimate.title}
              </p>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <div className="flex gap-2">
              {tabs.map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  onClick={() => handleTabChange(id as 'preview' | 'edit' | 'status')}
                  className={`
                    flex-1 sm:flex-none inline-flex items-center px-3 py-2 text-sm font-medium rounded-md
                    ${activeTab === id
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-500 hover:text-gray-700 bg-white'
                    }
                  `}
                >
                  <Icon className="h-4 w-4 mr-2" />
                  {label}
                </button>
              ))}
            </div>
            
            {activeTab === 'preview' && (
              <div className="flex gap-2 order-first sm:order-last">
                <ColorPicker />
                <Button
                  onClick={handlePrint}
                  loading={printing}
                  disabled={printing}
                  icon={Printer}
                  size="sm"
                >
                  Print
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative">
        {activeTab === 'preview' && (
          <EstimatePreview
            estimate={estimate}
            businessDetails={businessDetails}
            themeColor={themeColor}
          />
        )}

        {activeTab === 'status' && (
          <div className="bg-white shadow-lg rounded-lg">
            <StatusChanger
              currentStatus={estimate.status}
              onStatusChange={handleStatusChange}
              loading={updatingStatus}
            />
          </div>
        )}

        <FloatingShareButton 
          url={getShareUrl()} 
          title={`Estimate #${estimate.estimate_number}`}
          clientPhone={estimate.client?.phone}
          clientEmail={estimate.client?.email}
          businessLogo={businessDetails?.business_logo}
          businessName={businessDetails?.business_name}
        />
      </div>
    </div>
  );
};

export default ViewEstimate;