export const FONT_SIZES = {
  MOBILE: {
    HEADER: 16,
    SUBHEADER: 14,
    TITLE: 12,
    BODY: 10,
    SMALL: 9
  },
  DESKTOP: {
    HEADER: 18,
    SUBHEADER: 16,
    TITLE: 14,
    BODY: 12,
    SMALL: 10
  }
} as const;

export const FONT_FAMILIES = {
  REGULAR: 'helvetica',
  BOLD: 'helvetica-bold',
  OBLIQUE: 'helvetica-oblique'
} as const;

export interface FontConfig {
  size: number;
  family: string;
  style?: string;
}

export function getFontConfig(isMobile: boolean): Record<string, FontConfig> {
  const sizes = isMobile ? FONT_SIZES.MOBILE : FONT_SIZES.DESKTOP;
  
  return {
    header: {
      size: sizes.HEADER,
      family: FONT_FAMILIES.BOLD
    },
    subheader: {
      size: sizes.SUBHEADER,
      family: FONT_FAMILIES.BOLD
    },
    title: {
      size: sizes.TITLE,
      family: FONT_FAMILIES.BOLD
    },
    body: {
      size: sizes.BODY,
      family: FONT_FAMILIES.REGULAR
    },
    small: {
      size: sizes.SMALL,
      family: FONT_FAMILIES.REGULAR
    },
    bodyBold: {
      size: sizes.BODY,
      family: FONT_FAMILIES.BOLD
    },
    bodyOblique: {
      size: sizes.BODY,
      family: FONT_FAMILIES.OBLIQUE
    }
  };
}