import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from '../lib/supabase';

interface ThemeContextType {
  themeColor: string;
  setThemeColor: (color: string) => Promise<void>;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [themeColor, setThemeColorState] = useState('bg-indigo-600');

  useEffect(() => {
    if (user) {
      loadThemeColor();
    }
  }, [user]);

  const loadThemeColor = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('theme_color')
        .eq('id', user?.id)
        .single();

      if (error) throw error;
      if (data?.theme_color) {
        setThemeColorState(data.theme_color);
      }
    } catch (error) {
      console.error('Error loading theme color:', error);
    }
  };

  const setThemeColor = async (color: string) => {
    try {
      setThemeColorState(color);

      if (user) {
        const { error } = await supabase
          .from('profiles')
          .update({ theme_color: color })
          .eq('id', user.id);

        if (error) throw error;
      }
    } catch (error) {
      console.error('Error saving theme color:', error);
    }
  };

  return (
    <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};