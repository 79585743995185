import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreVertical } from 'lucide-react';
import { duplicateInvoice, deleteInvoice } from '../../lib/api/invoices';
import Button from '../shared/Button';
import InvoiceActionsModal from './InvoiceActionsModal';

interface InvoiceActionsMenuProps {
  invoiceId: string;
  onEdit: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
}

const InvoiceActionsMenu: React.FC<InvoiceActionsMenuProps> = ({
  invoiceId,
  onEdit,
  onDelete
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleDuplicate = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setDuplicating(true);
      const newInvoice = await duplicateInvoice(invoiceId);
      setIsOpen(false);
      navigate(`/invoices/${newInvoice.id}`);
    } catch (error) {
      console.error('Error duplicating invoice:', error);
    } finally {
      setDuplicating(false);
    }
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      setDeleting(true);
      await deleteInvoice(invoiceId);
      onDelete(e);
      setIsOpen(false);
    } catch (error) {
      console.error('Error deleting invoice:', error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="relative" onClick={e => e.stopPropagation()}>
      <Button
        variant="secondary"
        size="sm"
        onClick={handleMenuClick}
        className="!p-2"
      >
        <MoreVertical className="h-4 w-4" />
      </Button>

      <InvoiceActionsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onEdit={onEdit}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
        deleting={deleting}
        duplicating={duplicating}
      />
    </div>
  );
};

export default InvoiceActionsMenu;