import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getPricingItems, deletePricingItem, createPricingItem } from '../../lib/api/pricing';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PricingItemsTable from './PricingItemsTable';
import Button from '../shared/Button';
import { Copy } from 'lucide-react';
import type { PricingItem } from '../../types/pricing';

interface InstallationPricingTableProps {
  onEdit: (id: string) => void;
}

const InstallationPricingTable: React.FC<InstallationPricingTableProps> = ({ onEdit }) => {
  const { user } = useAuth();
  const [items, setItems] = useState<PricingItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [copyingId, setCopyingId] = useState<string | null>(null);

  useEffect(() => {
    fetchItems();
  }, [user]);

  const fetchItems = async () => {
    if (!user) return;

    try {
      setLoading(true);
      setError(null);
      const data = await getPricingItems(user.id, 'installation');
      setItems(data);
    } catch (err) {
      console.error('Error fetching pricing items:', err);
      setError('Failed to load pricing items');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setDeletingId(id);
      setError(null);
      await deletePricingItem(id);
      setItems(prevItems => prevItems.filter(item => item.id !== id));
    } catch (err) {
      console.error('Error deleting pricing item:', err);
      setError('Failed to delete item');
    } finally {
      setDeletingId(null);
    }
  };

  const handleCopyToMaterials = async (id: string) => {
    if (!user) return;

    try {
      setCopyingId(id);
      setError(null);

      const item = items.find(i => i.id === id);
      if (!item) throw new Error('Item not found');

      // Create new material item from installation item
      await createPricingItem({
        user_id: user.id,
        name: item.name,
        price: item.price,
        unit: item.unit,
        type: 'material',
        category: item.category,
        description: item.description,
        active: true
      });

    } catch (err) {
      console.error('Error copying to materials:', err);
      setError('Failed to copy item to materials');
    } finally {
      setCopyingId(null);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <PricingItemsTable
      items={items}
      onEdit={onEdit}
      onDelete={handleDelete}
      onCopyToMaterials={handleCopyToMaterials}
      deletingId={deletingId}
      copyingId={copyingId}
      showCategory={false}
    />
  );
};

export default InstallationPricingTable;