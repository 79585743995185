import React, { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import { supabase } from '../lib/supabase';
import type { LanguageCode } from '../utils/languages';

interface LanguageContextType {
  currentLanguage: LanguageCode;
  changeLanguage: (lang: LanguageCode) => Promise<void>;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    const loadUserLanguage = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('language_preference')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        if (data?.language_preference) {
          await i18n.changeLanguage(data.language_preference);
        }
      } catch (err) {
        console.error('Error loading user language preference:', err);
      }
    };

    loadUserLanguage();
  }, [user, i18n]);

  const changeLanguage = async (lang: LanguageCode) => {
    try {
      await i18n.changeLanguage(lang);
      
      if (user) {
        await supabase
          .from('profiles')
          .update({ language_preference: lang })
          .eq('id', user.id);
      }
    } catch (err) {
      console.error('Error changing language:', err);
    }
  };

  return (
    <LanguageContext.Provider 
      value={{ 
        currentLanguage: i18n.language as LanguageCode,
        changeLanguage 
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};