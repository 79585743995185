import { supabase } from '../supabase';
import type { Database } from '../../types/supabase';

type Invoice = Database['public']['Tables']['invoices']['Row'];
type InvoiceInsert = Database['public']['Tables']['invoices']['Insert'];
type InvoiceUpdate = Database['public']['Tables']['invoices']['Update'];

// Profile fields to select
const PROFILE_SELECT = `
  business_name,
  business_email,
  business_phone,
  business_address,
  business_city,
  business_state,
  business_zip,
  business_website,
  business_logo,
  theme_color
`;

export async function getInvoices(userId: string) {
  const { data, error } = await supabase
    .from('invoices')
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      ),
      estimate:estimates (
        id,
        estimate_number
      )
    `)
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching invoices:', error);
    throw error;
  }

  return data.map(invoice => ({
    ...invoice,
    items: typeof invoice.items === 'string' ? JSON.parse(invoice.items) : invoice.items,
    measurements: typeof invoice.measurements === 'string' ? JSON.parse(invoice.measurements) : invoice.measurements
  }));
}

export async function getInvoice(invoiceId: string) {
  const { data, error } = await supabase
    .from('invoices')
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      ),
      user:profiles!invoices_user_id_fkey (
        ${PROFILE_SELECT}
      ),
      estimate:estimates (
        id,
        estimate_number
      )
    `)
    .eq('id', invoiceId)
    .single();

  if (error) {
    console.error('Error fetching invoice:', error);
    throw error;
  }

  if (!data) {
    throw new Error('Invoice not found');
  }

  return {
    ...data,
    items: typeof data.items === 'string' ? JSON.parse(data.items) : data.items,
    measurements: typeof data.measurements === 'string' ? JSON.parse(data.measurements) : data.measurements
  };
}

export async function createInvoice(invoice: InvoiceInsert) {
  const { data, error } = await supabase
    .from('invoices')
    .insert({
      ...invoice,
      items: typeof invoice.items === 'string' 
        ? invoice.items 
        : JSON.stringify(invoice.items)
    })
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      ),
      estimate:estimates (
        id,
        estimate_number
      )
    `)
    .single();

  if (error) {
    console.error('Error creating invoice:', error);
    throw error;
  }

  return data;
}

export async function updateInvoice(invoiceId: string, invoice: InvoiceUpdate) {
  const { client, estimate, ...updateData } = invoice;

  const { data, error } = await supabase
    .from('invoices')
    .update({
      ...updateData,
      items: typeof updateData.items === 'string'
        ? updateData.items
        : JSON.stringify(updateData.items)
    })
    .eq('id', invoiceId)
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      ),
      estimate:estimates (
        id,
        estimate_number
      )
    `)
    .single();

  if (error) {
    console.error('Error updating invoice:', error);
    throw error;
  }

  return data;
}

export async function updateInvoiceStatus(
  invoiceId: string, 
  status: 'draft' | 'sent' | 'paid' | 'void'
) {
  const { data, error } = await supabase
    .from('invoices')
    .update({ status })
    .eq('id', invoiceId)
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      ),
      estimate:estimates (
        id,
        estimate_number
      )
    `)
    .single();

  if (error) {
    console.error('Error updating invoice status:', error);
    throw error;
  }

  return data;
}

export async function deleteInvoice(invoiceId: string): Promise<boolean> {
  try {
    const { error } = await supabase
      .from('invoices')
      .delete()
      .eq('id', invoiceId);

    if (error) {
      console.error('Error deleting invoice:', error);
      throw error;
    }

    return true;
  } catch (error) {
    console.error('Error in deleteInvoice:', error);
    throw error;
  }
}

export async function duplicateInvoice(invoiceId: string) {
  try {
    // First get the original invoice with client data
    const { data: originalInvoice, error: fetchError } = await supabase
      .from('invoices')
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        )
      `)
      .eq('id', invoiceId)
      .single();

    if (fetchError || !originalInvoice) {
      throw fetchError || new Error('Invoice not found');
    }

    // Prepare the new invoice data
    const { id, invoice_number, created_at, updated_at, client, ...invoiceData } = originalInvoice;
    
    const newInvoice = {
      ...invoiceData,
      title: `${invoiceData.title} (Copy)`,
      status: 'draft' as const,
      created_at: new Date().toISOString()
    };

    // Create the duplicate
    const { data: duplicate, error: createError } = await supabase
      .from('invoices')
      .insert(newInvoice)
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        )
      `)
      .single();

    if (createError || !duplicate) {
      throw createError || new Error('Failed to create duplicate');
    }

    return duplicate;
  } catch (error) {
    console.error('Error duplicating invoice:', error);
    throw error;
  }
}