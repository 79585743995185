import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import Button from '../shared/Button';
import ClientModal from '../clients/ClientModal';
import type { Client } from '../../types/clients';

interface NewClientButtonProps {
  onClientCreated: (newClient: Client) => void;
}

const NewClientButton: React.FC<NewClientButtonProps> = ({ onClientCreated }) => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const handleSuccess = (newClient: Client) => {
    onClientCreated(newClient);
    setShowModal(false);
  };

  return (
    <>
      <Button
        variant="secondary"
        icon={Plus}
        onClick={() => setShowModal(true)}
      >
        Add New Client
      </Button>

      {showModal && (
        <ClientModal
          onClose={() => setShowModal(false)}
          userId={user?.id}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default NewClientButton;