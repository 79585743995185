import { useState, useEffect } from 'react';

export interface ModalState {
  isOpen: boolean;
  formData: any;
  lastSaved?: string;
}

export function useModalPersistence(modalId: string, initialData: any = {}) {
  // Get stored state from localStorage
  const getStoredState = (): ModalState | null => {
    try {
      const stored = localStorage.getItem(`modal_state_${modalId}`);
      if (!stored) return null;
      
      const parsedState = JSON.parse(stored);
      // Validate stored state structure
      if (!parsedState || typeof parsedState !== 'object') return null;
      
      return parsedState;
    } catch (error) {
      console.error('Error retrieving modal state:', error);
      return null;
    }
  };

  // Initialize state from storage or defaults
  const [state, setState] = useState<ModalState>(() => {
    const stored = getStoredState();
    return stored || {
      isOpen: true, // Start as open when first created
      formData: initialData,
      lastSaved: new Date().toISOString()
    };
  });

  // Save state to localStorage whenever it changes
  useEffect(() => {
    try {
      if (state.isOpen) {
        localStorage.setItem(`modal_state_${modalId}`, JSON.stringify(state));
      }
    } catch (error) {
      console.error('Error saving modal state:', error);
    }
  }, [state, modalId]);

  // Auto-save form data every 30 seconds if there are changes
  useEffect(() => {
    let autoSaveInterval: NodeJS.Timeout;

    if (state.isOpen) {
      autoSaveInterval = setInterval(() => {
        setState(prev => ({
          ...prev,
          lastSaved: new Date().toISOString()
        }));
      }, 30000);
    }

    return () => {
      if (autoSaveInterval) {
        clearInterval(autoSaveInterval);
      }
    };
  }, [state.isOpen]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (!state.isOpen) {
        localStorage.removeItem(`modal_state_${modalId}`);
      }
    };
  }, [modalId, state.isOpen]);

  const openModal = (data: any = initialData) => {
    setState({
      isOpen: true,
      formData: data,
      lastSaved: new Date().toISOString()
    });
  };

  const closeModal = () => {
    setState(prev => ({ ...prev, isOpen: false }));
    localStorage.removeItem(`modal_state_${modalId}`);
  };

  const updateFormData = (newData: Partial<typeof state.formData>) => {
    setState(prev => ({
      ...prev,
      formData: { ...prev.formData, ...newData },
      lastSaved: new Date().toISOString()
    }));
  };

  return {
    isOpen: state.isOpen,
    formData: state.formData,
    lastSaved: state.lastSaved,
    openModal,
    closeModal,
    updateFormData
  };
}