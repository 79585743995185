import { supabase } from '../supabase';
import type { Client, ClientInsert, ClientUpdate } from '../../types/clients';

export async function getClients(userId: string): Promise<Client[]> {
  const { data, error } = await supabase
    .from('clients')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function getClient(clientId: string): Promise<Client> {
  const { data, error } = await supabase
    .from('clients')
    .select('*')
    .eq('id', clientId)
    .single();

  if (error) throw error;
  return data;
}

export async function createClient(client: ClientInsert): Promise<Client> {
  // Remove empty strings and convert them to null
  const cleanedClient = Object.fromEntries(
    Object.entries(client).map(([key, value]) => [
      key,
      value === '' ? null : value
    ])
  );

  // Ensure required fields are present
  if (!cleanedClient.name?.trim()) {
    throw new Error('Name is required');
  }

  const { data, error } = await supabase
    .from('clients')
    .insert(cleanedClient)
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function updateClient(clientId: string, client: ClientUpdate): Promise<Client> {
  // Remove empty strings and convert them to null
  const cleanedClient = Object.fromEntries(
    Object.entries(client).map(([key, value]) => [
      key,
      value === '' ? null : value
    ])
  );

  const { data, error } = await supabase
    .from('clients')
    .update(cleanedClient)
    .eq('id', clientId)
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function deleteClient(clientId: string): Promise<void> {
  const { error } = await supabase
    .from('clients')
    .delete()
    .eq('id', clientId);

  if (error) throw error;
}