import React from 'react';

interface ClientInfoProps {
  client: any;
  title: string;
  description?: string;
}

const ClientInfo: React.FC<ClientInfoProps> = ({ client, title, description }) => {
  if (!client) return null;

  return (
    <div className="px-4 sm:px-8 py-4 sm:py-6" data-section="client-info">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
        <div>
          <h3 className="text-base sm:text-lg font-medium text-gray-900">To:</h3>
          <div className="mt-2 space-y-1 text-sm sm:text-base">
            {client?.company && (
              <p className="text-gray-900 font-medium">{client.company}</p>
            )}
            {client?.name && (
              <p className="text-gray-600">{client.name}</p>
            )}
            {client?.email && (
              <p className="text-gray-600">{client.email}</p>
            )}
            {client?.phone && (
              <p className="text-gray-600">{client.phone}</p>
            )}
            {/* Add address fields */}
            {client?.street_address && (
              <p className="text-gray-600">{client.street_address}</p>
            )}
            {(client?.city || client?.state || client?.zip) && (
              <p className="text-gray-600">
                {[
                  client.city,
                  client.state,
                  client.zip
                ].filter(Boolean).join(', ')}
              </p>
            )}
            {client?.country && (
              <p className="text-gray-600">{client.country}</p>
            )}
          </div>
        </div>
        <div className="w-full sm:w-auto sm:text-right">
          <p className="text-gray-600 text-sm sm:text-base">Project:</p>
          <p className="text-gray-900 font-medium mt-2">{title}</p>
          {description && (
            <p className="text-gray-600 mt-1 max-w-xs text-sm sm:text-base whitespace-pre-wrap">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;