import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { createAppointment, updateAppointment } from '../../lib/api/appointments';
import Button from '../shared/Button';
import Select from '../shared/Select';
import MeasurementForm from './MeasurementForm';
import { Client, Measurement } from '../../types/appointments';
import { parseMeasurements } from '../../utils/measurements';

interface AppointmentFormProps {
  appointment?: any;
  clients: Client[];
  isEditing?: boolean;
  onSuccess?: () => void;
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({
  appointment,
  clients,
  isEditing = false,
  onSuccess
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    client_id: appointment?.client_id || '',
    title: appointment?.title || '',
    date: appointment?.date || '',
    start_time: appointment?.start_time || '',
    end_time: appointment?.end_time || '',
    location: appointment?.location || '',
    notes: appointment?.notes || '',
    status: appointment?.status || 'pending',
    measurements: parseMeasurements(appointment?.measurements || [])
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validateForm = (): string | null => {
    if (!formData.client_id) return 'Please select a client';
    if (!formData.title) return 'Please enter a title';
    if (!formData.date) return 'Please select a date';
    if (!formData.start_time) return 'Please select a start time';
    if (!formData.end_time) return 'Please select an end time';
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      setSubmitting(true);
      setError(null);

      const appointmentData = {
        ...formData,
        user_id: user.id,
        measurements: JSON.stringify(formData.measurements)
      };

      if (isEditing && appointment?.id) {
        await updateAppointment(appointment.id, appointmentData);
      } else {
        await createAppointment(appointmentData);
      }

      if (onSuccess) {
        onSuccess();
      } else {
        navigate('/appointments');
      }
    } catch (err) {
      console.error('Error saving appointment:', err);
      setError('Failed to save appointment');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form id="appointment-form" onSubmit={handleSubmit} className="divide-y divide-gray-200">
      {error && (
        <div className="p-4">
          <div className="rounded-md bg-red-50 p-4 border border-red-300">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      )}

      <div className="p-4 sm:p-6 space-y-6">
        {/* Client Selection */}
        <div className="bg-white rounded-lg">
          <label htmlFor="client_id" className="block text-sm font-medium text-gray-700 mb-1">
            Select Client
          </label>
          <Select
            id="client_id"
            value={formData.client_id}
            onChange={(e) => setFormData(prev => ({ ...prev, client_id: e.target.value }))}
            options={[
              { value: '', label: 'Select a client' },
              ...clients.map(client => ({
                value: client.id,
                label: `${client.company || ''} ${client.name}`.trim()
              }))
            ]}
            required
          />
        </div>

        {/* Title */}
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            id="title"
            value={formData.title}
            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/* Status */}
        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <Select
            id="status"
            value={formData.status}
            onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
            options={[
              { value: 'pending', label: 'Pending' },
              { value: 'confirmed', label: 'Confirmed' },
              { value: 'cancelled', label: 'Cancelled' }
            ]}
            required
          />
        </div>

        {/* Date and Time */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={formData.date}
              onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="start_time" className="block text-sm font-medium text-gray-700">
              Start Time
            </label>
            <input
              type="time"
              id="start_time"
              value={formData.start_time}
              onChange={(e) => setFormData(prev => ({ ...prev, start_time: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="end_time" className="block text-sm font-medium text-gray-700">
              End Time
            </label>
            <input
              type="time"
              id="end_time"
              value={formData.end_time}
              onChange={(e) => setFormData(prev => ({ ...prev, end_time: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            />
          </div>
        </div>

        {/* Location */}
        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <input
            type="text"
            id="location"
            value={formData.location}
            onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Notes */}
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
            Notes
          </label>
          <textarea
            id="notes"
            value={formData.notes}
            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Measurements */}
        <div className="relative">
          <MeasurementForm
            measurements={formData.measurements}
            onChange={(measurements) => setFormData(prev => ({ ...prev, measurements }))}
            clientId={formData.client_id}
          />
        </div>
      </div>

      {/* Fixed bottom buttons for mobile */}
      <div className="fixed bottom-16 left-0 right-0 bg-white border-t border-gray-200 p-4 sm:hidden z-50">
        <div className="flex justify-between gap-4">
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate('/appointments')}
            className="flex-1"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={submitting}
            disabled={submitting}
            className="flex-1"
          >
            {isEditing ? 'Update Appointment' : 'Create Appointment'}
          </Button>
        </div>
      </div>

      {/* Desktop submit button */}
      <div className="hidden sm:block fixed bottom-8 right-8 z-50">
        <div className="flex justify-end space-x-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate('/appointments')}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={submitting}
            disabled={submitting}
          >
            {isEditing ? 'Update Appointment' : 'Create Appointment'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AppointmentForm;