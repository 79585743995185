import html2canvas from 'html2canvas';
import { cleanupPDFElement } from './cleanup';
import { getDeviceSettings } from './device';
import { FONT_SIZES } from './fonts';

export async function createPDFCanvas(element: HTMLElement, scale: number): Promise<HTMLCanvasElement> {
  const deviceSettings = getDeviceSettings();
  const isMobile = deviceSettings.margin === 36; // Check if mobile based on margin
  const fontSizes = isMobile ? FONT_SIZES.MOBILE : FONT_SIZES.DESKTOP;
  
  // Pre-process element for better rendering
  element.style.width = element.offsetWidth + 'px';
  element.style.margin = '0';
  element.style.padding = '0';
  
  // Apply theme color from data attribute
  const themedElements = element.querySelectorAll('[data-theme-color]');
  themedElements.forEach(el => {
    if (el instanceof HTMLElement) {
      const themeColor = el.dataset.themeColor;
      if (themeColor) {
        try {
          const [r, g, b] = JSON.parse(themeColor);
          el.style.setProperty('--theme-color', `rgb(${r}, ${g}, ${b})`);
          if (el.tagName === 'TH' || el.classList.contains('theme-header')) {
            el.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
            el.style.color = 'white';
          }
        } catch (error) {
          console.error('Error parsing theme color:', error);
        }
      }
    }
  });
  
  // Apply font sizes
  const elements = element.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i] as HTMLElement;
    const computedStyle = window.getComputedStyle(el);
    
    // Adjust font sizes based on element type
    if (el.tagName === 'H1') {
      el.style.fontSize = `${fontSizes.HEADER}pt`;
    } else if (el.tagName === 'H2') {
      el.style.fontSize = `${fontSizes.SUBHEADER}pt`;
    } else if (el.tagName === 'H3') {
      el.style.fontSize = `${fontSizes.TITLE}pt`;
    } else {
      el.style.fontSize = `${fontSizes.BODY}pt`;
    }

    // Force background colors
    if (computedStyle.backgroundColor !== 'rgba(0, 0, 0, 0)') {
      el.style.backgroundColor = computedStyle.backgroundColor;
    }
  }

  return html2canvas(element, {
    scale: deviceSettings.scale * window.devicePixelRatio,
    useCORS: true,
    logging: false,
    allowTaint: true,
    backgroundColor: '#ffffff',
    windowWidth: element.offsetWidth,
    windowHeight: element.scrollHeight,
    imageTimeout: 15000,
    removeContainer: true,
    letterRendering: true,
    foreignObjectRendering: false, // Better compatibility on mobile
    scrollX: 0,
    scrollY: 0,
    onclone: (clonedDoc) => {
      const clonedElement = clonedDoc.getElementById(element.id);
      if (clonedElement) {
        cleanupPDFElement(clonedElement);
        
        // Ensure all fonts are loaded
        document.fonts.ready.then(() => {
          // Force repaint
          clonedElement.style.opacity = '0.99';
          setTimeout(() => {
            clonedElement.style.opacity = '1';
          }, 0);
        });
      }
    }
  });
}