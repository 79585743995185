import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, Calendar } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAppointments, deleteAppointment } from '../../lib/api/appointments';
import { getClients } from '../../lib/api/clients';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import AppointmentCard from '../../components/appointments/AppointmentCard';
import AppointmentStats from '../../components/appointments/AppointmentStats';
import FilteredAppointmentsModal from '../../components/appointments/FilteredAppointmentsModal';
import BottomNavigation from '../../components/shared/BottomNavigation';
import type { Appointment, Client } from '../../types/appointments';

const Appointments: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [showFilteredModal, setShowFilteredModal] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, [user]);

  const fetchData = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      const appointmentsData = await getAppointments(user.id);
      setAppointments(appointmentsData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load appointments');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (appointment: Appointment) => {
    navigate(`/appointments/edit/${appointment.id}`);
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this appointment?')) {
      return;
    }

    try {
      setDeletingId(id);
      await deleteAppointment(id);
      await fetchData();
    } catch (err) {
      console.error('Error deleting appointment:', err);
    } finally {
      setDeletingId(null);
    }
  };

  const handleStatusClick = (status: string) => {
    setFilteredStatus(status);
    setShowFilteredModal(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <LoadingSpinner />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <ErrorMessage message={error} />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Appointments</h1>
          <Link
            to="/appointments/new"
            className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            New Appointment
          </Link>
        </div>

        <div className="mt-8">
          <AppointmentStats 
            appointments={appointments}
            onStatusClick={handleStatusClick}
          />
        </div>

        <div className="mt-8 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {appointments.length > 0 ? (
            appointments.map((appointment) => (
              <AppointmentCard
                key={appointment.id}
                appointment={appointment}
                onEdit={handleEdit}
                onDelete={handleDelete}
                deletingId={deletingId}
              />
            ))
          ) : (
            <div className="col-span-full text-center py-12 bg-white rounded-lg shadow">
              <Calendar className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No appointments</h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new appointment.
              </p>
              <div className="mt-6">
                <Link
                  to="/appointments/new"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  <Plus className="h-5 w-5 mr-2" />
                  New Appointment
                </Link>
              </div>
            </div>
          )}
        </div>

        {showFilteredModal && filteredStatus && (
          <FilteredAppointmentsModal
            isOpen={showFilteredModal}
            onClose={() => setShowFilteredModal(false)}
            title={`${filteredStatus.charAt(0).toUpperCase()}${filteredStatus.slice(1)} Appointments`}
            appointments={appointments.filter(a => a.status === filteredStatus)}
            onAppointmentClick={(id) => {
              const appointment = appointments.find(a => a.id === id);
              if (appointment) {
                handleEdit(appointment);
              }
            }}
          />
        )}
      </div>
      <BottomNavigation />
    </div>
  );
};

export default Appointments;