import React from 'react';
import { FileText } from 'lucide-react';
import { formatDate } from '../../../utils/date';

interface BusinessHeaderProps {
  businessDetails: any;
  document: any;
  isInvoice?: boolean;
  themeColor?: string;
}

const BusinessHeader: React.FC<BusinessHeaderProps> = ({ 
  businessDetails, 
  document,
  isInvoice = false,
  themeColor = 'bg-indigo-600'
}) => {
  const documentType = isInvoice ? 'INVOICE' : 'ESTIMATE';
  const documentNumber = isInvoice ? document.invoice_number : document.estimate_number;
  const dateField = isInvoice ? 'due_date' : 'valid_until';
  const dateLabel = isInvoice ? 'Due Date' : 'Valid Until';

  return (
    <div className={`${themeColor} text-white w-full`} data-section="business-header">
      <div className="p-4 sm:p-8">
        <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
          {/* Business Info */}
          <div className="flex items-start gap-4">
            {businessDetails?.business_logo ? (
              <img
                src={businessDetails.business_logo}
                alt="Business Logo"
                className="h-16 w-16 sm:h-20 sm:w-20 object-contain bg-white rounded-lg p-2"
              />
            ) : (
              <div className="h-16 w-16 sm:h-20 sm:w-20 bg-white rounded-lg flex items-center justify-center">
                <FileText className={`h-8 w-8 sm:h-10 sm:w-10 ${themeColor.replace('bg-', 'text-')}`} />
              </div>
            )}
            <div>
              <h2 className="text-xl sm:text-2xl font-bold mb-2">
                {businessDetails?.business_name || 'Your Business Name'}
              </h2>
              <div className="space-y-0.5 text-sm sm:text-base text-white/90">
                {businessDetails?.business_address && (
                  <p>{businessDetails.business_address}</p>
                )}
                <p>
                  {businessDetails?.business_city && businessDetails.business_city}
                  {businessDetails?.business_state && `, ${businessDetails.business_state}`}
                  {businessDetails?.business_zip && ` ${businessDetails.business_zip}`}
                </p>
                {businessDetails?.business_phone && (
                  <p>{businessDetails.business_phone}</p>
                )}
                {businessDetails?.business_email && (
                  <p>{businessDetails.business_email}</p>
                )}
                {businessDetails?.business_website && (
                  <p>{businessDetails.business_website}</p>
                )}
              </div>
            </div>
          </div>

          {/* Document Info */}
          <div className="text-right">
            <h1 className="text-2xl sm:text-3xl font-bold mb-2">{documentType}</h1>
            <p className="text-xl sm:text-2xl font-bold">#{documentNumber}</p>
            <div className="mt-4 space-y-1 text-sm sm:text-base text-white/90">
              <p>
                <span className="inline-block w-20 text-white/70">Date:</span>
                {formatDate(document.created_at)}
              </p>
              <p>
                <span className="inline-block w-20 text-white/70">{dateLabel}:</span>
                {formatDate(document[dateField])}
              </p>
              <p>
                <span className="inline-block w-20 text-white/70">Status:</span>
                <span className="capitalize">{document.status}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessHeader;