import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const ColorPicker: React.FC = () => {
  const { themeColor, setThemeColor } = useTheme();
  const [showPicker, setShowPicker] = useState(false);

  const colorGroups = [
    {
      name: 'Red',
      colors: [
        { name: 'Red', value: 'bg-red-600' },
        { name: 'Rose', value: 'bg-rose-600' },
        { name: 'Pink', value: 'bg-pink-600' },
        { name: 'Fuchsia', value: 'bg-fuchsia-600' }
      ]
    },
    {
      name: 'Purple',
      colors: [
        { name: 'Purple', value: 'bg-purple-600' },
        { name: 'Violet', value: 'bg-violet-600' },
        { name: 'Indigo', value: 'bg-indigo-600' }
      ]
    },
    {
      name: 'Blue',
      colors: [
        { name: 'Blue', value: 'bg-blue-600' },
        { name: 'Sky', value: 'bg-sky-600' },
        { name: 'Cyan', value: 'bg-cyan-600' }
      ]
    },
    {
      name: 'Green',
      colors: [
        { name: 'Teal', value: 'bg-teal-600' },
        { name: 'Emerald', value: 'bg-emerald-600' },
        { name: 'Green', value: 'bg-green-600' },
        { name: 'Lime', value: 'bg-lime-600' }
      ]
    },
    {
      name: 'Yellow',
      colors: [
        { name: 'Yellow', value: 'bg-yellow-600' },
        { name: 'Amber', value: 'bg-amber-600' },
        { name: 'Orange', value: 'bg-orange-600' }
      ]
    },
    {
      name: 'Warm',
      colors: [
        { name: 'Brown', value: 'bg-amber-800' },
        { name: 'Maroon', value: 'bg-red-900' },
        { name: 'Sienna', value: 'bg-orange-800' }
      ]
    },
    {
      name: 'Cool',
      colors: [
        { name: 'Slate', value: 'bg-slate-600' },
        { name: 'Zinc', value: 'bg-zinc-600' },
        { name: 'Stone', value: 'bg-stone-600' }
      ]
    }
  ];

  const handleColorChange = async (color: string) => {
    await setThemeColor(color);
    setShowPicker(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowPicker(!showPicker)}
        className="flex items-center text-sm text-gray-500 hover:text-gray-700 focus:outline-none bg-white px-3 py-2 rounded-md border border-gray-300 shadow-sm"
      >
        <Palette className="h-4 w-4 mr-2" />
        <span className="hidden sm:inline">Theme Color</span>
      </button>

      {showPicker && (
        <>
          <div
            className="fixed inset-0 z-40"
            onClick={() => setShowPicker(false)}
          />
          <div className="absolute left-0 sm:right-0 sm:left-auto mt-2 p-4 bg-white rounded-lg shadow-xl z-50 w-72 max-h-[80vh] overflow-y-auto">
            <div className="space-y-6">
              {colorGroups.map((group) => (
                <div key={group.name} className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    {group.name}
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {group.colors.map((color) => (
                      <button
                        key={color.value}
                        onClick={() => handleColorChange(color.value)}
                        className={`
                          w-8 h-8 rounded-lg ${color.value}
                          ${themeColor === color.value ? 'ring-2 ring-offset-2 ring-gray-400' : ''}
                          transition-all duration-200
                        `}
                        title={color.name}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ColorPicker;