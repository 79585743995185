import { getFontConfig } from './fonts';
import { DEFAULT_MARGINS, IMAGE_SETTINGS } from './constants';

export function isMobileDevice(): boolean {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function getDevicePixelRatio(): number {
  return window.devicePixelRatio || 1;
}

export function getOptimalScale(baseScale: number): number {
  const pixelRatio = getDevicePixelRatio();
  if (isMobileDevice()) {
    return Math.min(baseScale * 0.75, 2 / pixelRatio);
  }
  return Math.min(baseScale, 3);
}

export function getDeviceSettings() {
  const isMobile = isMobileDevice();
  const imageSettings = isMobile ? IMAGE_SETTINGS.MOBILE : IMAGE_SETTINGS.DESKTOP;
  
  return {
    margin: isMobile ? DEFAULT_MARGINS.MOBILE : DEFAULT_MARGINS.DESKTOP,
    scale: getOptimalScale(2),
    imageQuality: imageSettings.QUALITY,
    compression: imageSettings.COMPRESSION,
    fonts: getFontConfig(isMobile)
  };
}