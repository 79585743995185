import React from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { formatCurrency } from '../../../utils/currency';

interface Item {
  description: string;
  quantity: number;
  rate: number;
}

interface ItemsTableProps {
  items: Item[];
  subtotal: number;
  taxRate: number;
  taxAmount: number;
  total: number;
  discount?: number;
  themeColor?: string;
}

const ItemsTable: React.FC<ItemsTableProps> = ({
  items = [],
  subtotal = 0,
  taxRate = 0,
  taxAmount = 0,
  total = 0,
  discount = 0,
  themeColor = 'bg-indigo-600'
}) => {
  const safeItems = Array.isArray(items) ? items : [];

  // Convert theme color to RGB values for PDF
  const getThemeRGB = () => {
    const colorMap: Record<string, [number, number, number]> = {
      'bg-red-600': [220, 38, 38],
      'bg-rose-600': [225, 29, 72],
      'bg-pink-600': [219, 39, 119],
      'bg-fuchsia-600': [192, 38, 211],
      'bg-purple-600': [147, 51, 234],
      'bg-violet-600': [124, 58, 237],
      'bg-indigo-600': [79, 70, 229],
      'bg-blue-600': [37, 99, 235],
      'bg-sky-600': [2, 132, 199],
      'bg-cyan-600': [8, 145, 178],
      'bg-teal-600': [13, 148, 136],
      'bg-emerald-600': [5, 150, 105],
      'bg-green-600': [22, 163, 74],
      'bg-lime-600': [101, 163, 13],
      'bg-yellow-600': [202, 138, 4],
      'bg-amber-600': [217, 119, 6],
      'bg-orange-600': [234, 88, 12],
      'bg-amber-800': [146, 64, 14],
      'bg-red-900': [127, 29, 29],
      'bg-orange-800': [154, 52, 18],
      'bg-slate-600': [71, 85, 105],
      'bg-zinc-600': [82, 82, 91],
      'bg-stone-600': [87, 83, 78]
    };
    return colorMap[themeColor] || [79, 70, 229];
  };

  return (
    <div className="w-full" data-section="items-table" data-theme-color={JSON.stringify(getThemeRGB())}>
      {/* Mobile View */}
      <div className="sm:hidden">
        <div className={`${themeColor} text-white grid grid-cols-4 gap-2 px-4 py-3 text-sm font-semibold`}>
          <div>Description</div>
          <div className="text-center">Qty</div>
          <div className="text-right">Rate</div>
          <div className="text-right">Amount</div>
        </div>
        
        <div className="bg-white divide-y divide-gray-200">
          {safeItems.map((item, index) => {
            const amount = (parseFloat(item.quantity.toString()) || 0) * 
                         (parseFloat(item.rate.toString()) || 0);
            return (
              <div key={index} className="grid grid-cols-4 gap-2 px-4 py-3 text-sm">
                <div className="break-words">{item.description}</div>
                <div className="text-center">{item.quantity}</div>
                <div className="text-right">{formatCurrency(item.rate)}</div>
                <div className="text-right font-medium">{formatCurrency(amount)}</div>
              </div>
            );
          })}
          
          {safeItems.length === 0 && (
            <div className="px-4 py-3 text-sm text-center text-gray-500">
              No items added
            </div>
          )}
        </div>

        {/* Mobile Summary */}
        <div className="mt-4 px-4 py-3 space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Subtotal:</span>
            <span className="font-medium">{formatCurrency(subtotal)}</span>
          </div>
          {discount > 0 && (
            <div className="flex justify-between text-sm">
              <span className="text-gray-600">Discount:</span>
              <span className="font-medium text-red-600">-{formatCurrency(discount)}</span>
            </div>
          )}
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Tax ({taxRate}%):</span>
            <span className="font-medium">{formatCurrency(taxAmount)}</span>
          </div>
          <div className="flex justify-between text-base font-bold pt-2 border-t border-gray-200">
            <span>Total:</span>
            <span className={themeColor.replace('bg-', 'text-')}>{formatCurrency(total)}</span>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className={themeColor}>
              <th className="px-6 py-3 text-left text-sm font-semibold text-white">Description</th>
              <th className="px-6 py-3 text-right text-sm font-semibold text-white">Quantity</th>
              <th className="px-6 py-3 text-right text-sm font-semibold text-white">Rate</th>
              <th className="px-6 py-3 text-right text-sm font-semibold text-white">Amount</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {safeItems.map((item, index) => {
              const amount = (parseFloat(item.quantity.toString()) || 0) * 
                           (parseFloat(item.rate.toString()) || 0);
              return (
                <tr key={index}>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-pre-wrap">
                    {item.description}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 text-right">
                    {item.quantity}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 text-right">
                    {formatCurrency(item.rate)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 text-right">
                    {formatCurrency(amount)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} className="px-6 py-3 text-sm font-medium text-gray-900 text-right">
                Subtotal:
              </td>
              <td className="px-6 py-3 text-sm text-gray-900 text-right">
                {formatCurrency(subtotal)}
              </td>
            </tr>
            {discount > 0 && (
              <tr>
                <td colSpan={3} className="px-6 py-3 text-sm font-medium text-gray-900 text-right">
                  Discount:
                </td>
                <td className="px-6 py-3 text-sm text-red-600 text-right">
                  -{formatCurrency(discount)}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={3} className="px-6 py-3 text-sm font-medium text-gray-900 text-right">
                Tax ({taxRate}%):
              </td>
              <td className="px-6 py-3 text-sm text-gray-900 text-right">
                {formatCurrency(taxAmount)}
              </td>
            </tr>
            <tr className="border-t-2 border-gray-900">
              <td colSpan={3} className="px-6 py-4 text-base font-bold text-gray-900 text-right">
                Total:
              </td>
              <td className={`px-6 py-4 text-base font-bold text-right ${themeColor.replace('bg-', 'text-')}`}>
                {formatCurrency(total)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default ItemsTable;