import { createClient } from '@supabase/supabase-js';
import { validateEnv } from '../utils/validation/env';

const env = validateEnv();

// Create Supabase client with retries and better error handling
export const supabase = createClient(
  env.VITE_SUPABASE_URL,
  env.VITE_SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true,
      storage: localStorage,
      storageKey: 'supabase.auth.token',
      flowType: 'pkce'
    },
    global: {
      headers: {
        'X-Client-Info': 'estimado-pro@1.0.0'
      }
    },
    db: {
      schema: 'public'
    },
    realtime: {
      params: {
        eventsPerSecond: 10
      }
    },
    // Add retry configuration
    fetch: (url, options = {}) => {
      const retryCount = 3;
      const retryDelay = 1000;

      const fetchWithRetry = async (attempt = 0): Promise<Response> => {
        try {
          const response = await fetch(url, {
            ...options,
            headers: {
              ...options.headers,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            }
          });

          if (!response.ok && attempt < retryCount) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          return response;
        } catch (error) {
          if (attempt < retryCount) {
            await new Promise(resolve => setTimeout(resolve, retryDelay * Math.pow(2, attempt)));
            return fetchWithRetry(attempt + 1);
          }
          throw error;
        }
      };

      return fetchWithRetry();
    }
  }
);

export const handleSupabaseError = (error: any): never => {
  console.error('[Supabase Error]:', error);
  
  // Network errors
  if (error?.message?.includes('Failed to fetch')) {
    throw {
      message: 'Network connection error. Please check your internet connection and try again.',
      code: 'NETWORK_ERROR',
      details: error?.details || error,
      hint: 'This may be a temporary issue. Please try refreshing the page.'
    };
  }

  // Authentication errors
  if (error?.message?.includes('JWT')) {
    throw {
      message: 'Your session has expired. Please sign in again.',
      code: 'AUTH_ERROR',
      details: error?.details || error,
      hint: 'Please sign out and sign back in to refresh your session.'
    };
  }

  // Rate limiting errors
  if (error?.message?.includes('rate limit')) {
    throw {
      message: 'Too many requests. Please try again later.',
      code: 'RATE_LIMIT',
      details: error?.details || error,
      hint: 'Please wait a moment before trying again.'
    };
  }

  // Database errors
  if (error?.code?.startsWith('23')) {
    throw {
      message: 'Database operation failed. Please try again.',
      code: error.code,
      details: error?.details || error,
      hint: error?.hint || 'Please check your input and try again.'
    };
  }

  // Generic error with improved message
  const errorMessage = error?.message || 'An unexpected error occurred';
  const errorCode = error?.code || 'UNKNOWN_ERROR';
  
  throw {
    message: errorMessage,
    code: errorCode,
    details: error?.details || error,
    hint: error?.hint || 'Please try again. If the problem persists, contact support.'
  };
};

// Add connection status monitoring
let isOnline = true;
let connectionCheckInterval: NodeJS.Timeout;

const checkConnection = async () => {
  try {
    const { data, error } = await supabase.from('profiles').select('count', { count: 'exact' }).limit(1);
    isOnline = !error;
  } catch {
    isOnline = false;
  }
};

// Start connection monitoring
export const startConnectionMonitoring = () => {
  checkConnection();
  connectionCheckInterval = setInterval(checkConnection, 30000); // Check every 30 seconds
};

// Stop connection monitoring
export const stopConnectionMonitoring = () => {
  if (connectionCheckInterval) {
    clearInterval(connectionCheckInterval);
  }
};

// Export connection status
export const isSupabaseOnline = () => isOnline;