import { supabase } from '../supabase';
import type { Database } from '../../types/supabase';
import type { Appointment } from '../../types/appointments';
import { parseMeasurements } from '../../utils/measurements';

type AppointmentInsert = Database['public']['Tables']['appointments']['Insert'];
type AppointmentUpdate = Database['public']['Tables']['appointments']['Update'];

export async function getAppointments(userId: string): Promise<Appointment[]> {
  const { data, error } = await supabase
    .from('appointments')
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  
  return data.map(appointment => ({
    ...appointment,
    measurements: parseMeasurements(appointment.measurements)
  }));
}

export async function getAppointment(appointmentId: string): Promise<Appointment> {
  const { data, error } = await supabase
    .from('appointments')
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .eq('id', appointmentId)
    .single();

  if (error) throw error;
  if (!data) throw new Error('Appointment not found');

  return {
    ...data,
    measurements: parseMeasurements(data.measurements)
  };
}

export async function createAppointment(appointment: AppointmentInsert): Promise<Appointment> {
  // Ensure measurements is stringified before saving
  const preparedAppointment = {
    ...appointment,
    measurements: typeof appointment.measurements === 'string' 
      ? appointment.measurements 
      : JSON.stringify(appointment.measurements)
  };

  const { data, error } = await supabase
    .from('appointments')
    .insert([preparedAppointment])
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .single();

  if (error) throw error;
  if (!data) throw new Error('Failed to create appointment');

  return {
    ...data,
    measurements: parseMeasurements(data.measurements)
  };
}

export async function updateAppointment(appointmentId: string, appointment: AppointmentUpdate): Promise<Appointment> {
  // Remove client field if present as it's a join field
  const { client, ...updateData } = appointment;

  // Ensure measurements is stringified before saving
  const preparedAppointment = {
    ...updateData,
    measurements: typeof updateData.measurements === 'string'
      ? updateData.measurements
      : JSON.stringify(updateData.measurements)
  };

  const { data, error } = await supabase
    .from('appointments')
    .update(preparedAppointment)
    .eq('id', appointmentId)
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .single();

  if (error) throw error;
  if (!data) throw new Error('Failed to update appointment');

  return {
    ...data,
    measurements: parseMeasurements(data.measurements)
  };
}

export async function deleteAppointment(appointmentId: string): Promise<void> {
  const { error } = await supabase
    .from('appointments')
    .delete()
    .eq('id', appointmentId);

  if (error) throw error;
}

export async function duplicateAppointment(appointmentId: string): Promise<Appointment> {
  try {
    // First get the original appointment with client data
    const originalAppointment = await getAppointment(appointmentId);

    if (!originalAppointment) {
      throw new Error('Appointment not found');
    }

    // Prepare the new appointment data
    const { id, created_at, updated_at, client, ...appointmentData } = originalAppointment;
    
    const newAppointment = {
      ...appointmentData,
      title: `${appointmentData.title} (Copy)`,
      status: 'pending' as const,
      measurements: typeof appointmentData.measurements === 'string'
        ? appointmentData.measurements
        : JSON.stringify(appointmentData.measurements)
    };

    // Create the duplicate
    const { data, error } = await supabase
      .from('appointments')
      .insert([newAppointment])
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        )
      `)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Failed to duplicate appointment');

    return {
      ...data,
      measurements: parseMeasurements(data.measurements)
    };
  } catch (error) {
    console.error('Error duplicating appointment:', error);
    throw error;
  }
}