import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { createInvoice } from '../../lib/api/invoices';
import Button from '../shared/Button';
import ItemsContainer from '../estimates/ItemsContainer';
import Select from '../shared/Select';
import FormSection from '../shared/FormSection';
import { Client } from '../../types/appointments';

interface InvoiceFormProps {
  invoice?: any;
  clients: Client[];
  isEditing?: boolean;
  onSuccess?: () => void;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  invoice,
  clients,
  isEditing = false,
  onSuccess
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    client_id: invoice?.client_id || '',
    title: invoice?.title || '',
    description: invoice?.description || '',
    items: invoice?.items ? 
      (typeof invoice.items === 'string' ? JSON.parse(invoice.items) : invoice.items) 
      : [],
    tax_rate: invoice?.tax_rate || 0,
    discount: invoice?.discount || 0,
    due_date: invoice?.due_date || '',
    notes: invoice?.notes || '',
    terms: invoice?.terms || '',
    status: invoice?.status || 'draft'
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validateForm = (): string | null => {
    if (!formData.client_id) return 'Please select a client';
    if (!formData.title) return 'Please enter a title';
    if (!formData.due_date) return 'Please select a due date';
    if (formData.items.length === 0) return 'Please add at least one item';

    const invalidItems = formData.items.filter(item => 
      !item.description || item.quantity <= 0 || item.rate <= 0
    );
    if (invalidItems.length > 0) {
      return 'Please complete all item fields with valid values';
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      setSubmitting(true);
      setError(null);

      const subtotal = formData.items.reduce((sum, item) => 
        sum + (item.quantity * item.rate), 0
      );
      const taxAmount = ((subtotal - formData.discount) * formData.tax_rate) / 100;
      const total = subtotal - formData.discount + taxAmount;

      const invoiceData = {
        user_id: user.id,
        client_id: formData.client_id,
        title: formData.title,
        description: formData.description,
        items: JSON.stringify(formData.items),
        subtotal,
        tax_rate: formData.tax_rate,
        tax_amount: taxAmount,
        discount: formData.discount,
        total,
        due_date: formData.due_date,
        notes: formData.notes,
        terms: formData.terms,
        status: formData.status
      };

      await createInvoice(invoiceData);
      onSuccess?.();
      navigate('/invoices');
    } catch (err) {
      console.error('Error saving invoice:', err);
      setError('Failed to save invoice');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form id="invoice-form" onSubmit={handleSubmit} className="divide-y divide-gray-200">
      {error && (
        <div className="p-4">
          <div className="rounded-md bg-red-50 p-4 border border-red-300">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      )}

      <FormSection>
        {/* Client Selection */}
        <div className="bg-white rounded-lg">
          <label htmlFor="client_id" className="block text-sm font-medium text-gray-700 mb-1">
            Select Client
          </label>
          <Select
            id="client_id"
            value={formData.client_id}
            onChange={(e) => setFormData({ ...formData, client_id: e.target.value })}
            options={[
              { value: '', label: 'Select a client' },
              ...clients.map(client => ({
                value: client.id,
                label: `${client.company || ''} ${client.name}`.trim()
              }))
            ]}
            required
          />
        </div>

        {/* Title & Description */}
        <div className="space-y-4 mt-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Items */}
        <div className="mt-6">
          <ItemsContainer
            items={formData.items}
            onChange={(items) => setFormData({ ...formData, items })}
            onTaxRateChange={(tax_rate) => setFormData({ ...formData, tax_rate })}
            onDiscountChange={(discount) => setFormData({ ...formData, discount })}
            onNotesChange={(notes) => setFormData({ ...formData, notes })}
            onTermsChange={(terms) => setFormData({ ...formData, terms })}
            notes={formData.notes}
            terms={formData.terms}
            taxRate={formData.tax_rate}
            discount={formData.discount}
            readOnly={false}
          />
        </div>

        {/* Due Date */}
        <div className="mt-6">
          <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
            Due Date
          </label>
          <input
            type="date"
            id="dueDate"
            value={formData.due_date}
            onChange={(e) => setFormData({ ...formData, due_date: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/* Bottom Spacer */}
        <div className="h-[80px] sm:h-[15px]" aria-hidden="true" />
      </FormSection>

      {/* Fixed bottom buttons for mobile */}
      <div className="fixed bottom-16 left-0 right-0 bg-white border-t border-gray-200 p-4 sm:hidden z-40">
        <div className="flex justify-between gap-4">
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate('/invoices')}
            className="flex-1"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={submitting}
            disabled={submitting}
            className="flex-1"
          >
            {isEditing ? 'Update Invoice' : 'Create Invoice'}
          </Button>
        </div>
      </div>

      {/* Desktop submit button */}
      <div className="hidden sm:block fixed bottom-8 right-8 z-40">
        <div className="flex justify-end space-x-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate('/invoices')}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={submitting}
            disabled={submitting}
          >
            {isEditing ? 'Update Invoice' : 'Create Invoice'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default InvoiceForm;