import { jsPDF } from 'jspdf';
import type { PDFDimensions } from './types';
import { A4_DIMENSIONS } from './constants';

export function calculatePDFDimensions(
  pdf: jsPDF,
  canvas: HTMLCanvasElement,
  margin: number
): PDFDimensions {
  // Get page dimensions in points
  const width = A4_DIMENSIONS.WIDTH;
  const height = A4_DIMENSIONS.HEIGHT;
  
  // Calculate content area
  const marginSize = margin;
  const contentWidth = width - (2 * marginSize);
  
  // Calculate scaling based on content width
  const scale = contentWidth / canvas.width;
  const scaledHeight = canvas.height * scale;
  
  // Calculate page breaks
  const pageHeight = height - (2 * marginSize);
  const totalPages = Math.ceil(scaledHeight / pageHeight);

  return {
    width,
    height,
    contentWidth,
    contentHeight: scaledHeight,
    pageHeight,
    marginSize,
    totalPages
  };
}

export function calculateImageDimensions(
  canvas: HTMLCanvasElement,
  pageWidth: number,
  margin: number
): { width: number; height: number } {
  const contentWidth = pageWidth - (2 * margin);
  const aspectRatio = canvas.height / canvas.width;
  const contentHeight = contentWidth * aspectRatio;

  return {
    width: contentWidth,
    height: contentHeight
  };
}