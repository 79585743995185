export const LANDING_IMAGES = {
  appointment: 'https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/Landingpageimages/WhatsApp%20Image%202024-12-04%20at%2021.17.57_5c022112.jpg',
  measurements: 'https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/Landingpageimages/WhatsApp%20Image%202024-12-04%20at%2021.53.43_01335e06.jpg',
  floorPlan: 'https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/Landingpageimages/WhatsApp%20Image%202024-12-04%20at%2021.17.58_9b3079f9.jpg',
  estimate: 'https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/Landingpageimages/WhatsApp%20Image%202024-12-04%20at%2021.53.43_7779a9a2.jpg',
  estimateRed: 'https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/Landingpageimages/WhatsApp%20Image%202024-12-04%20at%2021.53.43_de060270.jpg'
};

export const workflowSteps = [
  {
    title: "1. Schedule Client Appointments",
    description: "Easily create and manage appointments with clients. Add important details like location, date, time, and notes about what materials to bring.",
    image: LANDING_IMAGES.appointment,
    color: "bg-blue-500",
    features: [
      "Quick client selection",
      "Date and time scheduling",
      "Location tracking",
      "Important notes and reminders"
    ]
  },
  {
    title: "2. Record Area Measurements",
    description: "Take precise measurements during your visit. Our smart calculator automatically computes square footage as you input dimensions.",
    image: LANDING_IMAGES.floorPlan,
    color: "bg-green-500",
    features: [
      "Room-by-room measurements",
      "Automatic square footage calculation",
      "Notes for each area",
      "Real-time total area computation"
    ]
  },
  {
    title: "3. Generate Professional Floor Plans",
    description: "Transform your measurements into beautiful, professional floor plans with a single click. Perfect for including in your estimates.",
    image: LANDING_IMAGES.measurements,
    color: "bg-purple-500",
    features: [
      "Instant floor plan generation",
      "Professional layout",
      "Accurate dimensions",
      "Multiple room visualization"
    ]
  },
  {
    title: "4. Create Beautiful Estimates",
    description: "Combine everything into polished, professional estimates. Include floor plans, materials, labor costs, and terms all in one document.",
    image: LANDING_IMAGES.estimate,
    color: "bg-red-500",
    features: [
      "Professional estimate layout",
      "Integrated floor plans",
      "Automatic calculations",
      "Terms and conditions"
    ]
  }
];