import React, { useState, useRef, useEffect } from 'react';
import { Share2, Copy, MessageSquare, Check, Mail, Link } from 'lucide-react';
import Button from './Button';

interface ShareMenuProps {
  url: string;
  title?: string;
  clientPhone?: string;
  clientEmail?: string;
  businessLogo?: string;
  businessName?: string;
}

const ShareMenu: React.FC<ShareMenuProps> = ({ 
  url, 
  title = 'View Document',
  clientPhone,
  clientEmail,
  businessLogo,
  businessName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<'bottom' | 'top'>('bottom');
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && buttonRef.current && dropdownRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      
      const bottomSpace = windowHeight - buttonRect.bottom;
      const wouldOverflowBottom = bottomSpace < dropdownRect.height + 8;
      
      setDropdownPosition(wouldOverflowBottom ? 'top' : 'bottom');
    }
  }, [isOpen]);

  const formatMessage = (includeHeader: boolean = true) => {
    const header = businessName ? `${businessName}\n` : '';
    const content = `${title}\n\nView: ${url}`;
    return includeHeader ? `${header}${content}` : content;
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setShowCopyTooltip(true);
      setTimeout(() => setShowCopyTooltip(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      const textarea = document.createElement('textarea');
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      setShowCopyTooltip(true);
      setTimeout(() => setShowCopyTooltip(false), 2000);
    }
    setIsOpen(false);
  };

  const handleShareViaSMS = () => {
    const message = formatMessage();
    const phoneNumber = clientPhone?.replace(/\D/g, '');
    
    try {
      const userAgent = navigator.userAgent.toLowerCase();
      
      if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = phoneNumber 
          ? `sms:${phoneNumber}&body=${encodeURIComponent(message)}`
          : `sms:&body=${encodeURIComponent(message)}`;
      } else if (/android/.test(userAgent)) {
        window.location.href = phoneNumber 
          ? `sms:${phoneNumber}?body=${encodeURIComponent(message)}`
          : `sms:?body=${encodeURIComponent(message)}`;
      } else {
        if (navigator.share) {
          navigator.share({
            title,
            text: message,
            url
          }).catch(() => {
            window.location.href = phoneNumber 
              ? `sms:${phoneNumber}?body=${encodeURIComponent(message)}`
              : `sms:?body=${encodeURIComponent(message)}`;
          });
        } else {
          window.location.href = phoneNumber 
            ? `sms:${phoneNumber}?body=${encodeURIComponent(message)}`
            : `sms:?body=${encodeURIComponent(message)}`;
        }
      }
    } catch (error) {
      console.error('Error sharing via SMS:', error);
      navigator.clipboard.writeText(message).catch(console.error);
    }
    
    setIsOpen(false);
  };

  const handleShareViaEmail = () => {
    const message = formatMessage();
    const subject = encodeURIComponent(title);
    const body = encodeURIComponent(message);
    const mailtoUrl = clientEmail 
      ? `mailto:${encodeURIComponent(clientEmail)}?subject=${subject}&body=${body}`
      : `mailto:?subject=${subject}&body=${body}`;
    window.location.href = mailtoUrl;
    setIsOpen(false);
  };

  const handleNativeShare = async () => {
    if (!navigator.share) return;

    try {
      const message = formatMessage(false);
      await navigator.share({
        title,
        text: message,
        url
      });
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        console.error('Error sharing:', err);
      }
    }
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={buttonRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        icon={Share2}
        size="sm"
        className="relative"
      >
        Share
      </Button>

      {isOpen && (
        <div 
          ref={dropdownRef}
          className={`
            absolute ${dropdownPosition === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'}
            right-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50
            transform origin-top-right transition-transform duration-200 ease-out
          `}
          style={{ maxHeight: '90vh', overflowY: 'auto' }}
        >
          <div className="py-1" role="menu">
            {navigator.share && (
              <button
                onClick={handleNativeShare}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                <Share2 className="h-4 w-4 mr-3" />
                <span>Share...</span>
              </button>
            )}
            
            <button
              onClick={handleCopyToClipboard}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              {showCopyTooltip ? (
                <>
                  <Check className="h-4 w-4 mr-3 text-green-500" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <Link className="h-4 w-4 mr-3" />
                  <span>Copy Link</span>
                </>
              )}
            </button>

            <button
              onClick={handleShareViaSMS}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              <MessageSquare className="h-4 w-4 mr-3" />
              <span>Send via SMS {clientPhone ? '→' : ''}</span>
            </button>

            <button
              onClick={handleShareViaEmail}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              <Mail className="h-4 w-4 mr-3" />
              <span>Send via Email {clientEmail ? '→' : ''}</span>
            </button>
          </div>
        </div>
      )}

      {isOpen && (
        <div
          className="fixed inset-0 z-40"
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ShareMenu;