import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Plus } from 'lucide-react';
import Button from '../../components/shared/Button';
import InstallationPricingTable from '../../components/pricing/InstallationPricingTable';
import MaterialsPricingTable from '../../components/pricing/MaterialsPricingTable';

const PricingTableList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<'installation' | 'materials'>('installation');

  // Set active tab from location state if available
  useEffect(() => {
    const state = location.state as { activeTab?: 'installation' | 'materials' };
    if (state?.activeTab) {
      setActiveTab(state.activeTab);
      // Clear the state to prevent it from persisting
      navigate(location.pathname, { replace: true });
    }
  }, [location.state]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="sm:flex sm:items-center sm:justify-between mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Pricing Tables</h1>
        <Button
          variant="primary"
          icon={Plus}
          onClick={() => navigate(`/pricing/edit/${activeTab}`)}
        >
          Add New Item
        </Button>
      </div>

      <div className="mb-6">
        <nav className="flex space-x-4" aria-label="Tabs">
          <button
            onClick={() => setActiveTab('installation')}
            data-tab="installation"
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              activeTab === 'installation'
                ? 'bg-indigo-100 text-indigo-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Installation
          </button>
          <button
            onClick={() => setActiveTab('materials')}
            data-tab="materials"
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              activeTab === 'materials'
                ? 'bg-indigo-100 text-indigo-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Materials
          </button>
        </nav>
      </div>

      {activeTab === 'installation' ? (
        <InstallationPricingTable onEdit={(id) => navigate(`/pricing/edit/installation/${id}`)} />
      ) : (
        <MaterialsPricingTable onEdit={(id) => navigate(`/pricing/edit/materials/${id}`)} />
      )}
    </div>
  );
};

export default PricingTableList;