import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAppointment, updateAppointment } from '../../lib/api/appointments';
import { getClients } from '../../lib/api/clients';
import AppointmentForm from '../../components/appointments/AppointmentForm';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import Button from '../../components/shared/Button';
import BottomNavigation from '../../components/shared/BottomNavigation';

const EditAppointment: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState<any>(null);
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, [id, user]);

  const fetchData = async () => {
    if (!user || !id) return;

    try {
      setLoading(true);
      setError(null);

      const [appointmentData, clientsData] = await Promise.all([
        getAppointment(id),
        getClients(user.id)
      ]);

      if (!appointmentData) {
        throw new Error('Appointment not found');
      }

      const parsedAppointment = {
        ...appointmentData,
        measurements: appointmentData.measurements 
          ? typeof appointmentData.measurements === 'string'
            ? JSON.parse(appointmentData.measurements)
            : appointmentData.measurements
          : []
      };

      setAppointment(parsedAppointment);
      setClients(clientsData);
    } catch (err) {
      console.error('Error fetching appointment data:', err);
      setError('Failed to load appointment data');
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    navigate('/appointments');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <LoadingSpinner />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <ErrorMessage message={error} />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  if (!appointment) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <ErrorMessage message="Appointment not found" />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="sticky top-0 z-10 bg-white border-b border-gray-200 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between py-4">
          <div className="flex items-center">
            <button
              onClick={() => navigate('/appointments')}
              className="mr-4 text-gray-400 hover:text-gray-500"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-semibold text-gray-900">Edit Appointment</h1>
          </div>
        </div>
      </div>

      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 sm:p-6">
            <AppointmentForm
              appointment={appointment}
              clients={clients}
              isEditing={true}
              onSuccess={handleSuccess}
            />
          </div>
        </div>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default EditAppointment;