export async function printElement(elementId: string): Promise<void> {
  const element = document.getElementById(elementId);
  if (!element) return;

  // Create a clone of the element to avoid modifying the original
  const printContainer = element.cloneNode(true) as HTMLElement;
  printContainer.id = 'print-container';
  printContainer.classList.add('print-container');

  // Remove any duplicate sections in the clone
  const sections = printContainer.querySelectorAll('[data-section]');
  const seenSections = new Set<string>();
  sections.forEach(section => {
    const sectionId = section.getAttribute('data-section');
    if (sectionId) {
      if (seenSections.has(sectionId)) {
        section.remove();
      } else {
        seenSections.add(sectionId);
      }
    }
  });

  // Add the clone to the document
  document.body.appendChild(printContainer);

  // Create print styles
  const style = document.createElement('style');
  style.textContent = `
    @media print {
      @page {
        size: A4;
        margin: 10mm;
      }

      body > *:not(#print-container) {
        display: none !important;
      }

      #print-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        visibility: visible !important;
        print-color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
      }

      #print-container * {
        print-color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        forced-color-adjust: exact !important;
      }

      #print-container .bg-red-600 {
        background-color: #dc2626 !important;
        color: white !important;
      }

      #print-container th {
        background-color: #dc2626 !important;
        color: white !important;
      }

      .page-break {
        page-break-before: always !important;
      }
    }
  `;

  // Add print styles
  document.head.appendChild(style);

  try {
    // Wait for styles to be applied
    await new Promise(resolve => setTimeout(resolve, 100));

    // Print
    window.print();
  } finally {
    // Cleanup
    document.body.removeChild(printContainer);
    document.head.removeChild(style);
  }
}