export interface PDFOptions {
  filename: string;
  pageSize: 'a4' | 'letter';
  orientation: 'portrait' | 'landscape';
  margin: number;
  scale: number;
  unit: 'pt' | 'mm' | 'in';
  enableLinks: boolean;
  themeColor?: string;
}

export interface PDFDimensions {
  width: number;
  height: number;
  contentWidth: number;
  contentHeight: number;
  pageHeight: number;
  marginSize: number;
  totalPages: number;
}

export const DEFAULT_PDF_OPTIONS: PDFOptions = {
  filename: 'document.pdf',
  pageSize: 'a4',
  orientation: 'portrait',
  margin: 40,
  scale: 1.5,
  unit: 'pt',
  enableLinks: true,
  themeColor: 'bg-indigo-600'
};