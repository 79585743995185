import React from 'react';
import { Edit2, Trash2, Copy } from 'lucide-react';
import Button from '../shared/Button';
import { formatCurrency } from '../../utils/currency';
import { formatUnit } from '../../utils/pricing';
import type { PricingItem } from '../../types/pricing';

interface PricingItemsTableProps {
  items: PricingItem[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onCopyToMaterials?: (id: string) => void;
  deletingId: string | null;
  copyingId?: string | null;
  showCategory?: boolean;
}

const PricingItemsTable: React.FC<PricingItemsTableProps> = ({
  items,
  onEdit,
  onDelete,
  onCopyToMaterials,
  deletingId,
  copyingId,
  showCategory = false
}) => {
  const formatPriceWithUnit = (price: number, unit: string) => {
    return `${formatCurrency(price)}/${formatUnit(unit)}`;
  };

  // Mobile card view
  const MobileView = () => (
    <div className="space-y-4">
      {items.map((item) => (
        <div 
          key={item.id}
          className="bg-white rounded-lg shadow-sm border border-gray-200 p-4"
        >
          <div className="flex justify-between items-start mb-2">
            <div>
              <h3 className="font-medium text-gray-900">{item.name}</h3>
              <p className="text-sm text-gray-600 mt-1">
                {formatPriceWithUnit(item.price, item.unit)}
              </p>
            </div>
            <div className="flex space-x-2">
              {onCopyToMaterials && (
                <Button
                  variant="secondary"
                  size="sm"
                  icon={Copy}
                  onClick={() => onCopyToMaterials(item.id)}
                  loading={copyingId === item.id}
                  className="!p-2"
                  disabled={copyingId === item.id}
                  title="Copy to Materials"
                >
                  <span className="sr-only">Copy to Materials</span>
                </Button>
              )}
              <Button
                variant="secondary"
                size="sm"
                icon={Edit2}
                onClick={() => onEdit(item.id)}
                className="!p-2"
                title="Edit Item"
              >
                <span className="sr-only">Edit</span>
              </Button>
              <Button
                variant="secondary"
                size="sm"
                icon={Trash2}
                onClick={() => onDelete(item.id)}
                loading={deletingId === item.id}
                className="!p-2 text-red-600 hover:text-red-700"
                disabled={deletingId === item.id}
                title="Delete Item"
              >
                <span className="sr-only">Delete</span>
              </Button>
            </div>
          </div>
          {showCategory && item.category && (
            <p className="text-sm text-gray-500 mt-2">
              Category: {item.category}
            </p>
          )}
          {item.description && (
            <p className="text-sm text-gray-500 mt-2">
              {item.description}
            </p>
          )}
        </div>
      ))}
      {items.length === 0 && (
        <div className="text-center py-6 bg-white rounded-lg shadow-sm border border-gray-200">
          <p className="text-sm text-gray-500">
            No pricing items found. Click "Add New Item" to create one.
          </p>
        </div>
      )}
    </div>
  );

  // Desktop table view
  const DesktopView = () => (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              {showCategory && (
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
              )}
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rate
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {items.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.name}
                  {item.description && (
                    <p className="text-gray-500 text-xs mt-1">{item.description}</p>
                  )}
                </td>
                {showCategory && (
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.category || '-'}
                  </td>
                )}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatPriceWithUnit(item.price, item.unit)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div className="flex justify-end space-x-2">
                    {onCopyToMaterials && (
                      <Button
                        variant="secondary"
                        size="sm"
                        icon={Copy}
                        onClick={() => onCopyToMaterials(item.id)}
                        loading={copyingId === item.id}
                        className="!p-1"
                        disabled={copyingId === item.id}
                        title="Copy to Materials"
                      >
                        <span className="sr-only">Copy to Materials</span>
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      size="sm"
                      icon={Edit2}
                      onClick={() => onEdit(item.id)}
                      className="!p-1"
                      title="Edit Item"
                    >
                      <span className="sr-only">Edit</span>
                    </Button>
                    <Button
                      variant="secondary"
                      size="sm"
                      icon={Trash2}
                      onClick={() => onDelete(item.id)}
                      loading={deletingId === item.id}
                      className="!p-1 text-red-600 hover:text-red-700"
                      disabled={deletingId === item.id}
                      title="Delete Item"
                    >
                      <span className="sr-only">Delete</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      <div className="block md:hidden">
        <MobileView />
      </div>
      <div className="hidden md:block">
        <DesktopView />
      </div>
    </>
  );
};

export default PricingItemsTable;