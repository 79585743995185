import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import type { PDFOptions } from './types';
import { DEFAULT_PDF_OPTIONS } from './types';
import { createPDFCanvas } from './canvas';
import { calculatePDFDimensions } from './dimensions';

let isGenerating = false;
let generationTimeout: NodeJS.Timeout | null = null;

export async function generatePDF(elementId: string, options: Partial<PDFOptions> = {}): Promise<void> {
  if (isGenerating) return;

  try {
    isGenerating = true;

    // Clear any existing timeout
    if (generationTimeout) {
      clearTimeout(generationTimeout);
    }

    const finalOptions = {
      ...DEFAULT_PDF_OPTIONS,
      ...options
    };

    const element = document.getElementById(elementId);
    if (!element) throw new Error('Element not found');

    // Create PDF container
    const pdfContainer = element.cloneNode(true) as HTMLElement;
    pdfContainer.id = 'pdf-container';
    pdfContainer.classList.add('generating-pdf');

    // Apply theme color to all themed elements
    const themedElements = pdfContainer.querySelectorAll('[data-theme-color]');
    themedElements.forEach(el => {
      if (el instanceof HTMLElement) {
        el.dataset.themeColor = finalOptions.themeColor || 'bg-indigo-600';
      }
    });

    document.body.appendChild(pdfContainer);

    try {
      // Create canvas
      const canvas = await createPDFCanvas(pdfContainer, finalOptions.scale);

      // Initialize PDF
      const pdf = new jsPDF({
        orientation: finalOptions.orientation,
        unit: finalOptions.unit,
        format: finalOptions.pageSize,
        compress: true,
        putOnlyUsedFonts: true
      });

      // Calculate dimensions
      const dimensions = calculatePDFDimensions(pdf, canvas, finalOptions.margin);

      // Add content page by page
      let currentY = 0;
      const pageHeight = dimensions.pageHeight * canvas.height / dimensions.contentHeight;

      for (let i = 0; i < dimensions.totalPages; i++) {
        if (i > 0) {
          pdf.addPage();
        }

        const sourceY = currentY;
        const sourceHeight = Math.min(pageHeight, canvas.height - sourceY);
        
        pdf.addImage(
          canvas.toDataURL('image/jpeg', 1.0),
          'JPEG',
          dimensions.marginSize,
          dimensions.marginSize,
          dimensions.contentWidth,
          dimensions.pageHeight,
          undefined,
          'FAST',
          sourceY / canvas.height,
          0,
          1,
          sourceHeight / canvas.height
        );

        currentY += sourceHeight;
      }

      // Save PDF with a small delay to prevent duplicate downloads
      await new Promise<void>((resolve) => {
        requestAnimationFrame(() => {
          pdf.save(finalOptions.filename);
          setTimeout(resolve, 100);
        });
      });
    } finally {
      // Cleanup
      document.body.removeChild(pdfContainer);
    }
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  } finally {
    // Set a timeout before allowing another generation
    generationTimeout = setTimeout(() => {
      isGenerating = false;
      generationTimeout = null;
    }, 1000);
  }
}