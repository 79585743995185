import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import EstimatePreview from '../../components/estimates/EstimatePreview';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import DownloadButton from '../../components/estimates/DownloadButton';
import { parseMeasurements } from '../../utils/measurements';

const PublicInvoiceView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [invoice, setInvoice] = useState<any>(null);
  const [businessDetails, setBusinessDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchInvoice();
  }, [id]);

  useEffect(() => {
    // Update document title when invoice is loaded
    if (invoice) {
      document.title = `Invoice #${invoice.invoice_number} | EstimadoPro`;
    }
  }, [invoice]);

  const fetchInvoice = async () => {
    if (!id) return;

    try {
      setLoading(true);
      setError(null);

      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select(`
          *,
          client:clients (
            id,
            name,
            company,
            email,
            phone
          ),
          user:profiles!invoices_user_id_fkey (
            business_name,
            business_email,
            business_phone,
            business_address,
            business_city,
            business_state,
            business_zip,
            business_website,
            business_logo,
            theme_color
          )
        `)
        .eq('id', id)
        .single();

      if (invoiceError) throw new Error('This invoice is no longer available');
      if (!invoiceData) throw new Error('Invoice not found');

      const businessDetails = {
        business_name: invoiceData.user?.business_name,
        business_email: invoiceData.user?.business_email,
        business_phone: invoiceData.user?.business_phone,
        business_address: invoiceData.user?.business_address,
        business_city: invoiceData.user?.business_city,
        business_state: invoiceData.user?.business_state,
        business_zip: invoiceData.user?.business_zip,
        business_website: invoiceData.user?.business_website,
        business_logo: invoiceData.user?.business_logo
      };

      const parsedInvoice = {
        ...invoiceData,
        items: typeof invoiceData.items === 'string' 
          ? JSON.parse(invoiceData.items) 
          : invoiceData.items,
        measurements: parseMeasurements(invoiceData.measurements)
      };

      setInvoice(parsedInvoice);
      setBusinessDetails(businessDetails);
    } catch (err) {
      console.error('Error:', err);
      setError(err instanceof Error ? err.message : 'This invoice is no longer available');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <ErrorMessage message={error} />
      </div>
    );
  }

  if (!invoice) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <ErrorMessage message="Invoice not found" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 sm:py-8">
      <div className="max-w-4xl mx-auto px-0 sm:px-6 lg:px-8">
        <div className="mb-4 sm:mb-6 px-4 sm:px-0">
          <DownloadButton
            estimateNumber={invoice.invoice_number}
            className="w-full sm:w-auto"
            themeColor={invoice.user?.theme_color || 'bg-indigo-600'}
          />
        </div>

        <EstimatePreview
          estimate={invoice}
          businessDetails={businessDetails}
          isInvoice={true}
          themeColor={invoice.user?.theme_color || 'bg-indigo-600'}
        />
      </div>
    </div>
  );
};

export default PublicInvoiceView;