import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getPricingItem, createPricingItem, updatePricingItem } from '../../lib/api/pricing';
import Button from '../../components/shared/Button';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import PricingItemForm from '../../components/pricing/PricingItemForm';
import type { PricingItemFormData } from '../../types/pricing';

const EditPricingTable: React.FC = () => {
  const { type, id } = useParams<{ type: string; id?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<PricingItemFormData>({
    name: '',
    price: 0,
    unit: '',
    type: type === 'installation' ? 'installation' : 'material',
    category: '',
    description: ''
  });

  useEffect(() => {
    if (id) {
      fetchItem();
    }
  }, [id]);

  const fetchItem = async () => {
    if (!id) return;
    try {
      setLoading(true);
      const item = await getPricingItem(id);
      setFormData({
        name: item.name,
        price: item.price,
        unit: item.unit,
        type: item.type,
        category: item.category || '',
        description: item.description || ''
      });
    } catch (err) {
      console.error('Error fetching pricing item:', err);
      setError('Failed to load pricing item');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setSaving(true);
      setError(null);

      if (id) {
        await updatePricingItem(id, formData);
      } else {
        await createPricingItem({
          ...formData,
          user_id: user.id,
          active: true
        });
      }

      // Navigate back to pricing with the correct tab state
      navigate('/pricing', {
        state: { activeTab: formData.type }
      });
    } catch (err) {
      console.error('Error saving pricing item:', err);
      setError('Failed to save pricing item');
    } finally {
      setSaving(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-6">
        <button
          onClick={() => navigate('/pricing', { 
            state: { activeTab: formData.type } 
          })}
          className="flex items-center text-gray-500 hover:text-gray-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Pricing Tables
        </button>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-6">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">
          {id ? 'Edit' : 'Add'} {type === 'installation' ? 'Installation' : 'Material'} Price
        </h1>

        <PricingItemForm
          data={formData}
          onChange={setFormData}
          onSubmit={handleSubmit}
          onCancel={() => navigate('/pricing', {
            state: { activeTab: formData.type }
          })}
          loading={saving}
        />
      </div>
    </div>
  );
};

export default EditPricingTable;