import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAppointments } from '../lib/api/appointments';
import { Grid, Download, ChevronDown, ChevronUp, Building2 } from 'lucide-react';
import Card from '../components/shared/Card';
import Button from '../components/shared/Button';
import LoadingSpinner from '../components/shared/LoadingSpinner';
import ErrorMessage from '../components/shared/ErrorMessage';
import FullScreenFloorPlan from '../components/appointments/FullScreenFloorPlan';
import BottomNavigation from '../components/shared/BottomNavigation';
import { generateFloorPlanFromMeasurements } from '../utils/floorPlan';
import type { Appointment, Measurement } from '../types/appointments';

interface ClientFloorPlans {
  clientName: string;
  companyName: string;
  appointments: Appointment[];
}

const FloorPlanGenerator: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [clientFloorPlans, setClientFloorPlans] = useState<ClientFloorPlans[]>([]);
  const [expandedClient, setExpandedClient] = useState<string | null>(null);

  useEffect(() => {
    fetchAppointments();
  }, [user]);

  const fetchAppointments = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const appointments = await getAppointments(user.id);
      
      // Filter appointments with measurements and group by client
      const appointmentsWithMeasurements = appointments.filter(
        app => app.measurements && app.measurements.length > 0
      );

      const groupedByClient = appointmentsWithMeasurements.reduce((acc, appointment) => {
        const clientId = appointment.client.id;
        if (!acc[clientId]) {
          acc[clientId] = {
            clientName: appointment.client.name,
            companyName: appointment.client.company || '',
            appointments: []
          };
        }
        acc[clientId].appointments.push(appointment);
        return acc;
      }, {} as Record<string, ClientFloorPlans>);

      setClientFloorPlans(Object.values(groupedByClient));
    } catch (err) {
      console.error('Error fetching appointments:', err);
      setError('Failed to load floor plans');
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalArea = (appointments: Appointment[]) => {
    return appointments.reduce((total, appointment) => {
      return total + appointment.measurements.reduce((areaTotal, measurement) => {
        const width = parseFloat(measurement.width) || 0;
        const length = parseFloat(measurement.length) || 0;
        return areaTotal + (width * length);
      }, 0);
    }, 0);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <LoadingSpinner />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <ErrorMessage message={error} />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-900 mb-8">Floor Plans</h1>

        {clientFloorPlans.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <Grid className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No floor plans available</h3>
            <p className="mt-1 text-sm text-gray-500">
              Add measurements to your appointments to generate floor plans.
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            {clientFloorPlans.map(({ clientName, companyName, appointments }) => {
              const isExpanded = expandedClient === `${companyName}-${clientName}`;
              const totalArea = calculateTotalArea(appointments);
              const totalPlans = appointments.reduce((sum, app) => sum + app.measurements.length, 0);

              return (
                <Card key={`${companyName}-${clientName}`}>
                  <div 
                    className="p-6 cursor-pointer hover:bg-gray-50 transition-colors"
                    onClick={() => setExpandedClient(
                      isExpanded ? null : `${companyName}-${clientName}`
                    )}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
                          <Building2 className="h-6 w-6 text-indigo-600" />
                        </div>
                        <div>
                          <h2 className="text-lg font-medium text-gray-900">{companyName || clientName}</h2>
                          {companyName && <p className="text-sm text-gray-500">{clientName}</p>}
                        </div>
                      </div>
                      <div className="flex items-center space-x-8">
                        <div className="text-right">
                          <p className="text-sm font-medium text-gray-900">{totalPlans} Floor Plans</p>
                          <p className="text-sm text-gray-500">{totalArea.toFixed(2)} sq ft total</p>
                        </div>
                        {isExpanded ? (
                          <ChevronUp className="h-5 w-5 text-gray-400" />
                        ) : (
                          <ChevronDown className="h-5 w-5 text-gray-400" />
                        )}
                      </div>
                    </div>
                  </div>

                  {isExpanded && (
                    <div className="border-t border-gray-200 p-6">
                      {appointments.map((appointment) => (
                        <div key={appointment.id} className="mb-6 last:mb-0">
                          <h3 className="text-lg font-medium text-gray-900 mb-4">{appointment.title}</h3>
                          <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {appointment.measurements.map((measurement, index) => {
                              const image = generateFloorPlanFromMeasurements([measurement]);
                              const sqft = (parseFloat(measurement.width) * parseFloat(measurement.length)).toFixed(2);

                              return (
                                <div key={index} className="bg-gray-50 rounded-lg p-4">
                                  <div className="flex items-center justify-between mb-4">
                                    <div>
                                      <h4 className="text-base font-medium text-gray-900">
                                        {measurement.area}
                                      </h4>
                                      <p className="text-sm text-gray-500 mt-1">
                                        {measurement.width}' × {measurement.length}'
                                      </p>
                                    </div>
                                    <Button
                                      variant="secondary"
                                      size="sm"
                                      icon={Download}
                                      onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = image;
                                        link.download = `${appointment.title}-${measurement.area}.png`;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }}
                                      className="!p-2"
                                    >
                                      <span className="sr-only">Download</span>
                                    </Button>
                                  </div>

                                  <div className="aspect-[4/3] bg-white rounded-lg overflow-hidden mb-4">
                                    <img
                                      src={image}
                                      alt={`Floor plan for ${measurement.area}`}
                                      className="w-full h-full object-contain"
                                    />
                                  </div>

                                  <div className="text-sm text-gray-500">
                                    <p>Area: {sqft} sq ft</p>
                                    {measurement.notes && (
                                      <p className="mt-2 text-gray-600">{measurement.notes}</p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              );
            })}
          </div>
        )}
      </div>
      <BottomNavigation />
    </div>
  );
};

export default FloorPlanGenerator;