import React, { useState, useEffect } from 'react';
import { Grid, Plus, Check, X } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAppointments } from '../../lib/api/appointments';
import Button from '../shared/Button';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import type { Appointment, Measurement } from '../../types/appointments';

interface FloorPlanSelectorProps {
  clientId: string;
  selectedPlans: Measurement[];
  onAddPlan: (plan: Measurement) => void;
  onRemovePlan: (index: number) => void;
  onClose: () => void;
}

const FloorPlanSelector: React.FC<FloorPlanSelectorProps> = ({
  clientId,
  selectedPlans,
  onAddPlan,
  onRemovePlan,
  onClose
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedCount, setSelectedCount] = useState(selectedPlans.length);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  useEffect(() => {
    fetchAppointments();
  }, [clientId, user]);

  const fetchAppointments = async () => {
    if (!user || !clientId) return;

    try {
      setLoading(true);
      const data = await getAppointments(user.id);
      
      // Filter appointments for the selected client that have measurements
      const clientAppointments = data.filter(
        app => app.client.id === clientId && app.measurements?.length > 0
      );

      setAppointments(clientAppointments);
    } catch (err) {
      console.error('Error fetching appointments:', err);
      setError('Failed to load floor plans');
    } finally {
      setLoading(false);
    }
  };

  const isSelected = (measurement: Measurement) => {
    return selectedPlans.some(
      p => p.area === measurement.area && 
          p.width === measurement.width && 
          p.length === measurement.length
    );
  };

  const getSelectedIndex = (measurement: Measurement) => {
    return selectedPlans.findIndex(
      p => p.area === measurement.area && 
          p.width === measurement.width && 
          p.length === measurement.length
    );
  };

  const handleTogglePlan = (measurement: Measurement) => {
    const selected = isSelected(measurement);
    if (selected) {
      onRemovePlan(getSelectedIndex(measurement));
      setSelectedCount(prev => prev - 1);
    } else {
      onAddPlan(measurement);
      setSelectedCount(prev => prev + 1);
    }
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  if (loading) return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900">Loading Floor Plans</h3>
        <Button
          variant="secondary"
          size="sm"
          icon={X}
          onClick={handleClose}
          className="!p-2"
        >
          <span className="sr-only">Close</span>
        </Button>
      </div>
      <LoadingSpinner />
    </div>
  );

  if (error) return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900">Error</h3>
        <Button
          variant="secondary"
          size="sm"
          icon={X}
          onClick={handleClose}
          className="!p-2"
        >
          <span className="sr-only">Close</span>
        </Button>
      </div>
      <ErrorMessage message={error} />
      <div className="mt-6 flex justify-end">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-gray-900">Select Floor Plans</h3>
        <Button
          variant="secondary"
          size="sm"
          icon={X}
          onClick={handleClose}
          className="!p-2"
        >
          <span className="sr-only">Close</span>
        </Button>
      </div>

      <div className="bg-gray-50 px-4 py-2 rounded-lg flex items-center justify-between">
        <span className="text-sm text-gray-600">
          {selectedCount} plan{selectedCount !== 1 ? 's' : ''} selected
        </span>
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>

      {appointments.length > 0 ? (
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {appointments.map((appointment) => (
            <div key={appointment.id} className="space-y-4">
              <h4 className="font-medium text-gray-900">{appointment.title}</h4>
              {appointment.measurements.map((measurement, index) => {
                const selected = isSelected(measurement);
                const sqft = (parseFloat(measurement.width) * parseFloat(measurement.length)).toFixed(2);

                return (
                  <div 
                    key={index} 
                    className={`
                      bg-gray-50 rounded-lg p-4 cursor-pointer transition-colors
                      ${selected ? 'ring-2 ring-indigo-500 bg-indigo-50' : 'hover:bg-gray-100'}
                    `}
                    onClick={() => handleTogglePlan(measurement)}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h5 className="font-medium text-gray-900">{measurement.area}</h5>
                      {selected ? (
                        <Check className="h-5 w-5 text-indigo-600" />
                      ) : (
                        <Plus className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                    <p className="text-sm text-gray-600">
                      {measurement.width}' × {measurement.length}'
                    </p>
                    <p className="text-sm text-gray-600">
                      {sqft} sq ft
                    </p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <Grid className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No floor plans available</h3>
          <p className="mt-1 text-sm text-gray-500">
            Add measurements to appointments to generate floor plans.
          </p>
          <div className="mt-6">
            <Button
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloorPlanSelector;