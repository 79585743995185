import React from 'react';

const VideoSection: React.FC = () => {
  return (
    <div className="bg-gray-900 py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center mb-12">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">Watch Demo</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            See EstimadoPro in Action
          </p>
          <p className="mt-4 text-lg text-gray-300">
            Watch how easy it is to create professional estimates with floor plans
          </p>
        </div>
        
        <div className="relative mx-auto max-w-5xl rounded-2xl overflow-hidden shadow-2xl">
          <video 
            className="w-full aspect-video"
            controls
            autoPlay
            muted
            loop
            playsInline
          >
            <source 
              src="https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/Landingpageimages/1204(1).mp4?t=2024-12-05T04%3A03%3A13.230Z" 
              type="video/mp4" 
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;