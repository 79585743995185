import React from 'react';
import { X, FileSpreadsheet, Edit2, Trash2, Copy } from 'lucide-react';
import Button from '../shared/Button';

interface EstimateActionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEdit: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
  onDuplicate: (e: React.MouseEvent) => void;
  onConvertToInvoice: (e: React.MouseEvent) => void;
  converting: boolean;
  deleting: boolean;
  duplicating?: boolean;
}

const EstimateActionsModal: React.FC<EstimateActionsModalProps> = ({
  isOpen,
  onClose,
  onEdit,
  onDelete,
  onDuplicate,
  onConvertToInvoice,
  converting,
  deleting,
  duplicating = false
}) => {
  if (!isOpen) return null;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div 
          className="relative inline-block w-full max-w-sm transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
          onClick={handleClick}
        >
          {/* Close button */}
          <button
            type="button"
            className="absolute right-4 top-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <X className="h-6 w-6" />
          </button>

          {/* Modal content */}
          <div className="p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-8">
              Estimate Actions
            </h3>

            {/* Action buttons - vertical stack */}
            <div className="flex flex-col space-y-4">
              <Button
                onClick={onConvertToInvoice}
                disabled={converting || deleting || duplicating}
                loading={converting}
                icon={FileSpreadsheet}
                className="w-full justify-center text-base py-3"
              >
                {converting ? 'Converting...' : 'Convert to Invoice'}
              </Button>

              <Button
                onClick={onEdit}
                disabled={converting || deleting || duplicating}
                icon={Edit2}
                variant="secondary"
                className="w-full justify-center text-base py-3"
              >
                Edit Estimate
              </Button>

              <Button
                onClick={onDuplicate}
                disabled={converting || deleting || duplicating}
                loading={duplicating}
                icon={Copy}
                variant="secondary"
                className="w-full justify-center text-base py-3"
              >
                {duplicating ? 'Duplicating...' : 'Duplicate Estimate'}
              </Button>

              <Button
                onClick={onDelete}
                disabled={converting || deleting || duplicating}
                loading={deleting}
                icon={Trash2}
                variant="secondary"
                className="w-full justify-center text-base py-3 border-red-300 bg-white text-red-600 hover:bg-red-50 hover:text-red-700"
              >
                {deleting ? 'Deleting...' : 'Delete Estimate'}
              </Button>
            </div>

            {/* Cancel button */}
            <div className="mt-8">
              <Button
                type="button"
                variant="secondary"
                onClick={onClose}
                className="w-full justify-center text-base py-3"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateActionsModal;