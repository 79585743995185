import React, { useState, useEffect } from 'react';
import { Plus, Search, Mail, Phone, Building2, Edit2, Trash2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { getClients, deleteClient } from '../lib/api/clients';
import LoadingSpinner from '../components/shared/LoadingSpinner';
import ErrorMessage from '../components/shared/ErrorMessage';
import ClientModal from '../components/clients/ClientModal';
import FloatingContactButton from '../components/shared/FloatingContactButton';
import Button from '../components/shared/Button';
import BottomNavigation from '../components/shared/BottomNavigation';

const Clients: React.FC = () => {
  const { user } = useAuth();
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [importedContact, setImportedContact] = useState<any>(null);

  useEffect(() => {
    fetchClients();
  }, [user]);

  useEffect(() => {
    if (importedContact) {
      setSelectedClient(null);
      setIsModalOpen(true);
    }
  }, [importedContact]);

  const fetchClients = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const data = await getClients(user.id);
      setClients(data);
    } catch (err) {
      setError('Failed to load clients');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClient = () => {
    setSelectedClient(null);
    setImportedContact(null);
    setIsModalOpen(true);
  };

  const handleEditClient = (client: any) => {
    setSelectedClient(client);
    setImportedContact(null);
    setIsModalOpen(true);
  };

  const handleDeleteClient = async (clientId: string) => {
    if (!window.confirm('Are you sure you want to delete this client?')) {
      return;
    }

    try {
      await deleteClient(clientId);
      await fetchClients();
    } catch (err) {
      console.error('Failed to delete client:', err);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
    setImportedContact(null);
    fetchClients();
  };

  const handleContactSelect = async () => {
    try {
      // @ts-ignore - ContactsManager API types not yet in TypeScript
      const contacts = await navigator.contacts.select(
        ['name', 'email', 'tel', 'organization'],
        { multiple: false }
      );

      if (contacts.length > 0) {
        const contact = contacts[0];
        setImportedContact({
          name: contact.name?.[0] || '',
          email: contact.email?.[0] || '',
          phone: contact.tel?.[0] || '',
          company: contact.organization?.[0] || ''
        });
      }
    } catch (err) {
      console.error('Error selecting contact:', err);
      if (err instanceof Error && err.name !== 'NotAllowedError') {
        alert('Error accessing contacts. Please try again or enter contact details manually.');
      }
    }
  };

  const filteredClients = clients.filter(
    (client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <LoadingSpinner />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <ErrorMessage message={error} />
        </div>
        <BottomNavigation />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Clients</h1>
          <Button
            onClick={handleAddClient}
            variant="primary"
            icon={Plus}
          >
            Add Client
          </Button>
        </div>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search clients..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-8 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {filteredClients.map((client) => (
            <div
              key={client.id}
              className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow"
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
                        <Building2 className="h-6 w-6 text-indigo-600" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium text-gray-900">{client.name}</h3>
                      {client.company && (
                        <p className="text-sm text-gray-500">{client.company}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditClient(client)}
                      className="p-2 text-gray-400 hover:text-gray-500"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteClient(client.id)}
                      className="p-2 text-gray-400 hover:text-red-500"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  {client.email && (
                    <div className="flex items-center text-sm text-gray-500">
                      <Mail className="h-4 w-4 mr-2" />
                      {client.email}
                    </div>
                  )}
                  {client.phone && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <Phone className="h-4 w-4 mr-2" />
                      {client.phone}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          {filteredClients.length === 0 && (
            <div className="col-span-full text-center py-12 bg-white rounded-lg shadow">
              <Building2 className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No clients found</h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new client.
              </p>
              <div className="mt-6">
                <Button
                  onClick={handleAddClient}
                  variant="primary"
                  icon={Plus}
                >
                  Add Client
                </Button>
              </div>
            </div>
          )}
        </div>

        {isModalOpen && (
          <ClientModal
            client={selectedClient}
            onClose={handleModalClose}
            userId={user?.id}
            importedContact={importedContact}
          />
        )}

        <FloatingContactButton onClick={handleContactSelect} />
      </div>
      <BottomNavigation />
    </div>
  );
};

export default Clients;