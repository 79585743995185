import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreVertical } from 'lucide-react';
import { createInvoiceFromEstimate, duplicateEstimate, deleteEstimate } from '../../lib/api/estimates';
import Button from '../shared/Button';
import EstimateActionsModal from './EstimateActionsModal';

interface EstimateActionsMenuProps {
  estimateId: string;
  onEdit: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
}

const EstimateActionsMenu: React.FC<EstimateActionsMenuProps> = ({
  estimateId,
  onEdit,
  onDelete
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [converting, setConverting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleConvertToInvoice = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setConverting(true);
      const invoice = await createInvoiceFromEstimate(estimateId);
      setIsOpen(false);
      navigate(`/invoices/${invoice.id}`);
    } catch (error) {
      console.error('Error converting to invoice:', error);
    } finally {
      setConverting(false);
    }
  };

  const handleDuplicate = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setDuplicating(true);
      const newEstimate = await duplicateEstimate(estimateId);
      setIsOpen(false);
      navigate(`/estimates/${newEstimate.id}`);
    } catch (error) {
      console.error('Error duplicating estimate:', error);
    } finally {
      setDuplicating(false);
    }
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      setDeleting(true);
      await deleteEstimate(estimateId);
      setIsOpen(false);
      onDelete(e);
    } catch (error) {
      console.error('Error deleting estimate:', error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="relative" onClick={e => e.stopPropagation()}>
      <Button
        variant="secondary"
        size="sm"
        onClick={handleMenuClick}
        className="!p-2"
      >
        <MoreVertical className="h-4 w-4" />
      </Button>

      <EstimateActionsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onEdit={onEdit}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
        onConvertToInvoice={handleConvertToInvoice}
        converting={converting}
        deleting={deleting}
        duplicating={duplicating}
      />
    </div>
  );
};

export default EstimateActionsMenu;