import React, { useState } from 'react';
import { Grid } from 'lucide-react';
import Button from '../shared/Button';
import FloorPlanSelector from './FloorPlanSelector';
import FloorPlanSection from './preview/FloorPlanSection';
import type { Measurement } from '../../types/appointments';

interface MeasurementFormProps {
  measurements: Measurement[];
  onChange: (measurements: Measurement[]) => void;
  clientId?: string;
  readOnly?: boolean;
}

const MeasurementForm: React.FC<MeasurementFormProps> = ({
  measurements = [],
  onChange,
  clientId,
  readOnly = false
}) => {
  const [showSelector, setShowSelector] = useState(false);
  const [tempMeasurements, setTempMeasurements] = useState<Measurement[]>([]);

  const handleOpenSelector = () => {
    setTempMeasurements([...measurements]);
    setShowSelector(true);
  };

  const handleCloseSelector = () => {
    onChange(tempMeasurements);
    setShowSelector(false);
  };

  const handleAddPlan = (plan: Measurement) => {
    setTempMeasurements(prev => [...prev, plan]);
  };

  const handleRemovePlan = (index: number) => {
    if (showSelector) {
      setTempMeasurements(prev => prev.filter((_, i) => i !== index));
    } else {
      onChange(measurements.filter((_, i) => i !== index));
    }
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleCloseSelector();
    }
  };

  if (readOnly) {
    return measurements.length > 0 ? (
      <FloorPlanSection floorPlans={measurements} />
    ) : null;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Floor Plans</h3>
        {clientId && (
          <Button
            type="button"
            variant="secondary"
            size="sm"
            icon={Grid}
            onClick={handleOpenSelector}
          >
            Manage Floor Plans
          </Button>
        )}
      </div>

      {measurements.length > 0 ? (
        <FloorPlanSection 
          floorPlans={measurements} 
          onRemoveFloorPlan={handleRemovePlan}
        />
      ) : (
        <div className="text-center py-6 bg-gray-50 rounded-lg">
          <Grid className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No floor plans added</h3>
          <p className="mt-1 text-sm text-gray-500">
            Click "Manage Floor Plans" to add floor plans from appointments.
          </p>
        </div>
      )}

      {showSelector && clientId && (
        <div 
          className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
          onClick={handleBackdropClick}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Select Floor Plans
                  </h3>
                </div>

                <FloorPlanSelector
                  clientId={clientId}
                  selectedPlans={tempMeasurements}
                  onAddPlan={handleAddPlan}
                  onRemovePlan={handleRemovePlan}
                  onClose={handleCloseSelector}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeasurementForm;