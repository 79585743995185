import React from 'react';
import { Globe } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { SUPPORTED_LANGUAGES, type LanguageCode } from '../../utils/languages';

interface LanguageSelectorProps {
  variant?: 'minimal' | 'full';
  className?: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ 
  variant = 'full',
  className = '' 
}) => {
  const { currentLanguage, changeLanguage } = useLanguage();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeLanguage(e.target.value as LanguageCode);
  };

  if (variant === 'minimal') {
    return (
      <div className={`relative ${className}`}>
        <select
          value={currentLanguage}
          onChange={handleChange}
          className="appearance-none bg-transparent pl-8 pr-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 rounded-md"
        >
          {SUPPORTED_LANGUAGES.map(({ code, flag }) => (
            <option key={code} value={code}>
              {flag}
            </option>
          ))}
        </select>
        <Globe className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
      </div>
    );
  }

  return (
    <div className={`relative ${className}`}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Globe className="h-5 w-5 text-gray-400" />
      </div>
      <select
        value={currentLanguage}
        onChange={handleChange}
        className="block w-full pl-10 pr-3 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {SUPPORTED_LANGUAGES.map(({ code, name, flag }) => (
          <option key={code} value={code}>
            {flag} {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;