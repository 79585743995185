import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, FileText, Clock, CheckCircle, XCircle, BarChart2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getInvoices } from '../../lib/api/invoices';
import { formatCurrency } from '../../utils/currency';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import FilteredTableModal from '../../components/shared/FilteredTableModal';
import StatsCard from '../../components/shared/StatsCard';
import InvoiceActionsMenu from '../../components/invoices/InvoiceActionsMenu';

const InvoiceList: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [showFilteredTable, setShowFilteredTable] = useState(false);

  useEffect(() => {
    fetchInvoices();
  }, [user]);

  const fetchInvoices = async () => {
    try {
      if (!user) return;
      setLoading(true);
      const data = await getInvoices(user.id);
      setInvoices(data);
    } catch (err) {
      console.error('Error fetching invoices:', err);
      setError('Failed to load invoices');
    } finally {
      setLoading(false);
    }
  };

  const handleInvoiceClick = (id: string) => {
    navigate(`/invoices/${id}`);
  };

  const handleEditClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/invoices/edit/${id}`);
  };

  const handleDeleteClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    // Remove invoice from state immediately
    setInvoices(prevInvoices => prevInvoices.filter(invoice => invoice.id !== id));
  };

  const handleStatsCardClick = (status: string) => {
    setSelectedStatus(status);
    setShowFilteredTable(true);
  };

  const getFilteredInvoices = () => {
    if (!selectedStatus) return [];
    return invoices.filter(i => i.status === selectedStatus);
  };

  const stats = {
    totalInvoices: invoices.length,
    draftInvoices: invoices.filter(i => i.status === 'draft').length,
    pendingInvoices: invoices.filter(i => i.status === 'sent').length,
    paidInvoices: invoices.filter(i => i.status === 'paid').length,
    voidInvoices: invoices.filter(i => i.status === 'void').length,
    totalRevenue: invoices
      .filter(i => i.status === 'paid')
      .reduce((sum, i) => sum + i.total, 0),
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center sm:justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Invoices</h1>
        <Link
          to="/invoices/new"
          className="mt-4 sm:mt-0 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800"
        >
          <Plus className="h-5 w-5 mr-2" />
          New Invoice
        </Link>
      </div>

      {/* Stats Cards */}
      <div className="mt-8 grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-6">
        <StatsCard
          icon={FileText}
          title="Total Invoices"
          value={stats.totalInvoices}
        />
        <StatsCard
          icon={FileText}
          title="Draft"
          value={stats.draftInvoices}
          iconColor="text-gray-400"
          onClick={() => handleStatsCardClick('draft')}
        />
        <StatsCard
          icon={Clock}
          title="Pending"
          value={stats.pendingInvoices}
          iconColor="text-yellow-400"
          onClick={() => handleStatsCardClick('sent')}
        />
        <StatsCard
          icon={CheckCircle}
          title="Paid"
          value={stats.paidInvoices}
          iconColor="text-green-400"
          onClick={() => handleStatsCardClick('paid')}
        />
        <StatsCard
          icon={XCircle}
          title="Void"
          value={stats.voidInvoices}
          iconColor="text-red-400"
          onClick={() => handleStatsCardClick('void')}
        />
        <StatsCard
          icon={BarChart2}
          title="Revenue"
          value={formatCurrency(stats.totalRevenue)}
          iconColor="text-indigo-400"
        />
      </div>

      <div className="mt-8 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Invoice #
              </th>
              <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                Client
              </th>
              <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                Amount
              </th>
              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {invoices.map((invoice) => (
              <tr
                key={invoice.id}
                onClick={() => handleInvoiceClick(invoice.id)}
                className="cursor-pointer hover:bg-gray-50"
              >
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-900">{invoice.invoice_number}</div>
                    <div className="ml-4 lg:hidden">
                      <div className="font-medium text-gray-900">{invoice.client?.name}</div>
                    </div>
                  </div>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {invoice.client?.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500">
                  <span className="font-medium text-gray-900">
                    {formatCurrency(invoice.total)}
                  </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                  <InvoiceActionsMenu
                    invoiceId={invoice.id}
                    onEdit={(e) => handleEditClick(e, invoice.id)}
                    onDelete={(e) => handleDeleteClick(e, invoice.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Filtered Table Modal */}
      <FilteredTableModal
        isOpen={showFilteredTable}
        onClose={() => setShowFilteredTable(false)}
        title={`${selectedStatus?.charAt(0).toUpperCase()}${selectedStatus?.slice(1)} Invoices`}
        items={getFilteredInvoices()}
        type="invoice"
        onItemClick={handleInvoiceClick}
      />
    </div>
  );
};

export default InvoiceList;