import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';
import Button from '../shared/Button';
import AIEstimateModal from './AIEstimateModal';

interface AIEstimateButtonProps {
  onEstimateCreated: (estimateData: any) => void;
}

const AIEstimateButton: React.FC<AIEstimateButtonProps> = ({ onEstimateCreated }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowModal(true)}
        className="ml-2"
      >
        <Sparkles className="h-5 w-5 mr-2 text-indigo-600" />
        AI Estimate
      </Button>

      {showModal && (
        <AIEstimateModal
          onClose={() => setShowModal(false)}
          onEstimateCreated={onEstimateCreated}
        />
      )}
    </>
  );
};

export default AIEstimateButton;