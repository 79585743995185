import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EstimateList from './estimates/EstimateList';
import NewEstimate from './estimates/NewEstimate';
import ViewEstimate from './estimates/ViewEstimate';
import EditEstimate from './estimates/EditEstimate';
import BottomNavigation from '../components/shared/BottomNavigation';

const Estimates: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <Routes>
        <Route path="/" element={<EstimateList />} />
        <Route path="/new" element={<NewEstimate />} />
        <Route path="/:id" element={<ViewEstimate />} />
        <Route path="/edit/:id" element={<EditEstimate />} />
      </Routes>
      <BottomNavigation />
    </div>
  );
};

export default Estimates;