import React, { useState, useRef } from 'react';
import { Download } from 'lucide-react';
import Button from '../shared/Button';
import { generatePDF } from '../../utils/pdf';

interface DownloadButtonProps {
  estimateNumber: string;
  className?: string;
  themeColor?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ 
  estimateNumber, 
  className = '',
  themeColor = 'bg-indigo-600'
}) => {
  const [downloading, setDownloading] = useState(false);
  const downloadTimeoutRef = useRef<NodeJS.Timeout>();

  const handleDownload = async () => {
    if (downloading) return;

    try {
      setDownloading(true);

      // Clear any existing timeout
      if (downloadTimeoutRef.current) {
        clearTimeout(downloadTimeoutRef.current);
      }

      await generatePDF('estimate-content', {
        filename: `estimate-${estimateNumber}.pdf`,
        pageSize: 'a4',
        orientation: 'portrait',
        margin: 40,
        scale: window.innerWidth <= 768 ? 2 : 1.5,
        enableLinks: true,
        themeColor
      });
    } catch (err) {
      console.error('Error downloading PDF:', err);
    } finally {
      // Set a timeout to prevent rapid re-clicks
      downloadTimeoutRef.current = setTimeout(() => {
        setDownloading(false);
      }, 1500);
    }
  };

  // Cleanup timeout on unmount
  React.useEffect(() => {
    return () => {
      if (downloadTimeoutRef.current) {
        clearTimeout(downloadTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Button
      onClick={handleDownload}
      loading={downloading}
      disabled={downloading}
      icon={Download}
      size="sm"
      className={className}
    >
      Download PDF
    </Button>
  );
};

export default DownloadButton;