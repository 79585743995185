import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, X, BarChart2, Users, FileText, Building2, LogOut, Calendar, Grid, FileSpreadsheet, Settings } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import LanguageSelector from '../shared/LanguageSelector';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { signOut } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  // Hide header on public routes and estimate/invoice preview routes
  if (
    ['/login', '/'].includes(location.pathname) ||
    location.pathname.startsWith('/e/') || // Public estimate routes
    location.pathname.startsWith('/i/')    // Public invoice routes
  ) {
    return null;
  }

  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      setIsOpen(false);
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const mainNavItems = [
    { path: '/dashboard', label: t('nav.dashboard'), icon: BarChart2 },
    { path: '/estimates', label: t('nav.estimates'), icon: FileText },
    { path: '/invoices', label: t('nav.invoices'), icon: FileSpreadsheet },
    { path: '/appointments', label: t('nav.appointments'), icon: Calendar },
    { path: '/clients', label: t('nav.clients'), icon: Users },
  ];

  const settingsNavItems = [
    { path: '/pricing', label: 'Pricing Tables', icon: Grid },
    { path: '/floor-plan', label: t('nav.floorPlan'), icon: Grid },
    { path: '/business', label: t('nav.business'), icon: Building2 },
  ];

  return (
    <header className="bg-white shadow-sm">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to="/dashboard" className="flex items-center">
              <BarChart2 className="h-8 w-8 text-indigo-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">EstimadoPro</span>
            </Link>
          </div>

          <div className="hidden sm:flex sm:items-center sm:space-x-8">
            {mainNavItems.map(({ path, label }) => (
              <Link
                key={path}
                to={path}
                className={`text-gray-700 hover:text-indigo-600 ${
                  location.pathname.startsWith(path) ? 'text-indigo-600' : ''
                }`}
              >
                {label}
              </Link>
            ))}
            
            {/* Settings Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                className="flex items-center text-gray-700 hover:text-indigo-600"
              >
                <Settings className="h-5 w-5" />
              </button>

              {isSettingsOpen && (
                <>
                  <div
                    className="fixed inset-0 z-10"
                    onClick={() => setIsSettingsOpen(false)}
                  />
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
                    <div className="py-1">
                      {settingsNavItems.map(({ path, label, icon: Icon }) => (
                        <Link
                          key={path}
                          to={path}
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => setIsSettingsOpen(false)}
                        >
                          <Icon className="h-4 w-4 mr-3" />
                          {label}
                        </Link>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
            
            <LanguageSelector variant="minimal" />
            
            <button
              onClick={handleLogout}
              className="flex items-center text-gray-700 hover:text-indigo-600"
            >
              <LogOut className="h-5 w-5 mr-1" />
              <span>{t('auth.signOut')}</span>
            </button>
          </div>

          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
            >
              <span className="sr-only">
                {isOpen ? 'Close menu' : 'Open menu'}
              </span>
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </nav>

      {isOpen && (
        <div className="sm:hidden bg-white border-t border-gray-200">
          <div className="pt-2 pb-3 space-y-1">
            {[...mainNavItems, ...settingsNavItems].map(({ path, label, icon: Icon }) => (
              <Link
                key={path}
                to={path}
                onClick={() => setIsOpen(false)}
                className={`flex items-center px-3 py-2 text-base font-medium hover:text-indigo-600 hover:bg-gray-50 ${
                  location.pathname.startsWith(path) 
                    ? 'text-indigo-600 bg-indigo-50' 
                    : 'text-gray-700'
                }`}
              >
                <Icon className="h-5 w-5 mr-3" />
                {label}
              </Link>
            ))}
            
            <div className="px-3 py-2">
              <LanguageSelector />
            </div>
            
            <button
              onClick={handleLogout}
              className="w-full flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
            >
              <LogOut className="h-5 w-5 mr-3" />
              {t('auth.signOut')}
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;