import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, FileText, Clock, CheckCircle, XCircle, Calendar, Users, Sparkles } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { getEstimates, deleteEstimate } from '../lib/api/estimates';
import { getAppointments } from '../lib/api/appointments';
import { getClients } from '../lib/api/clients';
import { formatCurrency } from '../utils/currency';
import { formatDate, formatTime } from '../utils/date';
import LoadingSpinner from '../components/shared/LoadingSpinner';
import ErrorMessage from '../components/shared/ErrorMessage';
import Card from '../components/shared/Card';
import EstimateActionsMenu from '../components/estimates/EstimateActionsMenu';
import FilteredTableModal from '../components/shared/FilteredTableModal';
import StatsCard from '../components/shared/StatsCard';
import AIEstimateButton from '../components/estimates/AIEstimateButton';
import BottomNavigation from '../components/shared/BottomNavigation';
import ClientModal from '../components/clients/ClientModal';
import type { Appointment, Client } from '../types/appointments';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [estimates, setEstimates] = useState<any[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [showFilteredTable, setShowFilteredTable] = useState(false);
  const [showNewClientModal, setShowNewClientModal] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, [user]);

  const fetchDashboardData = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const [estimatesData, appointmentsData] = await Promise.all([
        getEstimates(user.id),
        getAppointments(user.id)
      ]);

      // Filter approved estimates and sort by creation date (most recent first)
      const approvedEstimates = estimatesData
        .filter(estimate => estimate.status === 'approved')
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .slice(0, 5);

      // Filter pending appointments and sort by date
      const pendingAppointments = appointmentsData
        .filter(appointment => appointment.status === 'pending')
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .slice(0, 5);

      setEstimates(approvedEstimates);
      setAppointments(pendingAppointments);
    } catch (err) {
      console.error('Dashboard data error:', err);
      setError('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  const handleViewAppointment = (appointmentId: string) => {
    navigate(`/appointments`);
  };

  const handleEstimateClick = (id: string) => {
    navigate(`/estimates/${id}`);
  };

  const handleEditEstimate = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/estimates/edit/${id}`);
  };

  const handleDeleteEstimate = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      setDeletingId(id);
      await deleteEstimate(id);
      await fetchDashboardData();
    } catch (err) {
      console.error('Failed to delete estimate:', err);
      if (err instanceof Error) {
        alert(err.message);
      } else {
        alert('Failed to delete estimate');
      }
    } finally {
      setDeletingId(null);
    }
  };

  const handleStatsCardClick = (status: string) => {
    setSelectedStatus(status);
    setShowFilteredTable(true);
  };

  const getFilteredEstimates = () => {
    if (!selectedStatus) return [];
    return estimates.filter(e => e.status === selectedStatus);
  };

  const handleAIEstimateCreated = (newEstimate: any) => {
    navigate(`/estimates/${newEstimate.id}`);
  };

  const handleNewClientSuccess = (newClient: Client) => {
    setShowNewClientModal(false);
    // Navigate to new estimate form after client is created
    navigate('/estimates/new');
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
          <div className="mt-4 sm:mt-0 flex flex-col sm:flex-row gap-2">
            <div className="flex space-x-2">
              <Link
                to="/estimates/new"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800"
              >
                <Plus className="h-5 w-5 mr-2" />
                <span className="sm:inline">New Estimate</span>
              </Link>
              <AIEstimateButton onEstimateCreated={handleAIEstimateCreated} />
            </div>
            <Link
              to="/appointments/new"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Calendar className="h-5 w-5 mr-2" />
              <span className="sm:inline">New Appointment</span>
            </Link>
          </div>
        </div>

        {/* Recent Estimates & Appointments */}
        <div className="mt-8 grid gap-8 lg:grid-cols-2">
          {/* Recent Approved Estimates */}
          <div>
            <div className="sm:flex sm:items-center sm:justify-between">
              <h2 className="text-lg font-medium text-gray-900">Recent Approved Estimates</h2>
              <Link
                to="/estimates"
                className="mt-4 sm:mt-0 text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all
              </Link>
            </div>

            <div className="mt-4 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="min-w-full overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Estimate #
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                        Client
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                        Amount
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {estimates.length > 0 ? (
                      estimates.map((estimate) => (
                        <tr
                          key={estimate.id}
                          onClick={() => handleEstimateClick(estimate.id)}
                          className="cursor-pointer hover:bg-gray-50"
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="font-medium text-gray-900">{estimate.estimate_number}</div>
                              <div className="ml-4 lg:hidden">
                                <div className="font-medium text-gray-900">{estimate.client?.name}</div>
                              </div>
                            </div>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {estimate.client?.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500">
                            <span className="font-medium text-gray-900">
                              {formatCurrency(estimate.total)}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                            <EstimateActionsMenu
                              estimateId={estimate.id}
                              onEdit={(e) => handleEditEstimate(e, estimate.id)}
                              onDelete={(e) => handleDeleteEstimate(e, estimate.id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} className="px-3 py-4 text-sm text-center text-gray-500">
                          No approved estimates yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Recent Pending Appointments */}
          <div>
            <div className="sm:flex sm:items-center sm:justify-between">
              <h2 className="text-lg font-medium text-gray-900">Recent Pending Appointments</h2>
              <Link
                to="/appointments"
                className="mt-4 sm:mt-0 text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all
              </Link>
            </div>

            <div className="mt-4 space-y-4">
              {appointments.length > 0 ? (
                appointments.map((appointment) => (
                  <div
                    key={appointment.id}
                    className="block cursor-pointer hover:shadow-lg transition-shadow"
                    onClick={() => handleViewAppointment(appointment.id)}
                  >
                    <Card>
                      <div className="p-4">
                        <div className="flex items-center justify-between">
                          <h3 className="text-base font-medium text-gray-900">
                            {appointment.title}
                          </h3>
                          <span className="inline-flex rounded-full px-2 py-1 text-xs font-semibold bg-yellow-100 text-yellow-800">
                            Pending
                          </span>
                        </div>
                        
                        <div className="mt-2 space-y-1">
                          <div className="flex items-center text-sm text-gray-500">
                            <Calendar className="h-4 w-4 mr-2" />
                            {formatDate(appointment.date)}
                          </div>
                          <div className="flex items-center text-sm text-gray-500">
                            <Clock className="h-4 w-4 mr-2" />
                            {formatTime(appointment.start_time)} - {formatTime(appointment.end_time)}
                          </div>
                          <div className="flex items-center text-sm text-gray-500">
                            <Users className="h-4 w-4 mr-2" />
                            {appointment.client?.name}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))
              ) : (
                <div className="text-center py-6 bg-white rounded-lg shadow">
                  <p className="text-sm text-gray-500">No pending appointments</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Filtered Table Modal */}
        <FilteredTableModal
          isOpen={showFilteredTable}
          onClose={() => setShowFilteredTable(false)}
          title={`${selectedStatus?.charAt(0).toUpperCase()}${selectedStatus?.slice(1)} Estimates`}
          items={getFilteredEstimates()}
          type="estimate"
          onItemClick={handleEstimateClick}
        />

        {/* New Client Modal */}
        {showNewClientModal && (
          <ClientModal
            onClose={() => setShowNewClientModal(false)}
            userId={user?.id}
            onSuccess={handleNewClientSuccess}
          />
        )}

        {/* Bottom Navigation */}
        <BottomNavigation />
      </div>
    </div>
  );
};

export default Dashboard;