import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PricingTableList from './pricing/PricingTableList';
import EditPricingTable from './pricing/EditPricingTable';
import BottomNavigation from '../components/shared/BottomNavigation';

const PricingTables: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <Routes>
        <Route path="/" element={<PricingTableList />} />
        <Route path="/edit/:type" element={<EditPricingTable />} />
        <Route path="/edit/:type/:id" element={<EditPricingTable />} />
      </Routes>
      <BottomNavigation />
    </div>
  );
};

export default PricingTables;