import React from 'react';
import Input from '../shared/Input';

interface AddressFieldsProps {
  address: {
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
  onChange: (field: string, value: string) => void;
}

const AddressFields: React.FC<AddressFieldsProps> = ({ address, onChange }) => {
  return (
    <div className="space-y-4">
      <Input
        label="Street Address"
        type="text"
        value={address.street || ''}
        onChange={(e) => onChange('street', e.target.value)}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Input
          label="City"
          type="text"
          value={address.city || ''}
          onChange={(e) => onChange('city', e.target.value)}
        />

        <Input
          label="State/Province"
          type="text"
          value={address.state || ''}
          onChange={(e) => onChange('state', e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Input
          label="ZIP/Postal Code"
          type="text"
          value={address.zip || ''}
          onChange={(e) => onChange('zip', e.target.value)}
        />

        <Input
          label="Country"
          type="text"
          value={address.country || ''}
          onChange={(e) => onChange('country', e.target.value)}
        />
      </div>
    </div>
  );
};

export default AddressFields;