import { supabase } from '../supabase';
import type { PricingItem } from '../../types/pricing';

export async function getPricingItems(userId: string, type: 'installation' | 'material'): Promise<PricingItem[]> {
  try {
    const { data, error } = await supabase
      .from('pricing_items')
      .select('*')
      .eq('user_id', userId)
      .eq('type', type)
      .eq('active', true)
      .order('name');

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching pricing items:', error);
    throw error;
  }
}

export async function getPricingItem(id: string): Promise<PricingItem> {
  try {
    const { data, error } = await supabase
      .from('pricing_items')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Pricing item not found');
    return data;
  } catch (error) {
    console.error('Error fetching pricing item:', error);
    throw error;
  }
}

export async function createPricingItem(item: Omit<PricingItem, 'id' | 'created_at'>): Promise<PricingItem> {
  try {
    const { data, error } = await supabase
      .from('pricing_items')
      .insert([item])
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Failed to create pricing item');
    return data;
  } catch (error) {
    console.error('Error creating pricing item:', error);
    throw error;
  }
}

export async function updatePricingItem(id: string, item: Partial<PricingItem>): Promise<PricingItem> {
  try {
    const { data, error } = await supabase
      .from('pricing_items')
      .update(item)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Pricing item not found');
    return data;
  } catch (error) {
    console.error('Error updating pricing item:', error);
    throw error;
  }
}

export async function deletePricingItem(id: string): Promise<boolean> {
  try {
    // First, get the item to verify ownership
    const { data: item, error: fetchError } = await supabase
      .from('pricing_items')
      .select('id')
      .eq('id', id)
      .single();

    if (fetchError) throw fetchError;
    if (!item) throw new Error('Pricing item not found');

    // Then delete the item
    const { error: deleteError } = await supabase
      .from('pricing_items')
      .delete()
      .eq('id', id);

    if (deleteError) throw deleteError;
    return true;
  } catch (error) {
    console.error('Error deleting pricing item:', error);
    throw error;
  }
}