import React, { useState, useEffect } from 'react';
import { Sparkles } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAppointments } from '../../lib/api/appointments';
import { createEstimate } from '../../lib/api/estimates';
import Button from '../shared/Button';
import Select from '../shared/Select';
import { useModalPersistence } from '../../hooks/useModalPersistence';
import PersistentModal from '../shared/PersistentModal';
import type { Appointment } from '../../types/appointments';

interface AIEstimateModalProps {
  onClose: () => void;
  onEstimateCreated: (newEstimate: any) => void;
}

const AIEstimateModal: React.FC<AIEstimateModalProps> = ({ onClose, onEstimateCreated }) => {
  const { user } = useAuth();
  const {
    isOpen,
    formData,
    lastSaved,
    updateFormData,
    closeModal
  } = useModalPersistence('ai-estimate', {
    selectedAppointment: '',
    taxRate: 0,
    discount: 0,
    notes: '',
    terms: ''
  });

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [savingDraft, setSavingDraft] = useState(false);

  useEffect(() => {
    fetchAppointments();
  }, [user]);

  const fetchAppointments = async () => {
    if (!user) return;

    try {
      const data = await getAppointments(user.id);
      const appointmentsWithMeasurements = data.filter(app => 
        app.measurements && app.measurements.length > 0 && 
        app.measurements.some(m => m.items && m.items.length > 0)
      );
      setAppointments(appointmentsWithMeasurements);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setError('Failed to load appointments');
    }
  };

  const formatAreaList = (areas: Set<string>): string => {
    const areaList = Array.from(areas);
    if (areaList.length === 0) return '';
    if (areaList.length === 1) return areaList[0];
    if (areaList.length === 2) return `${areaList[0]} and ${areaList[1]}`;
    
    const lastArea = areaList.pop();
    return `${areaList.join(', ')}, and ${lastArea}`;
  };

  const createItemDescription = (
    name: string, 
    areas: Set<string>, 
    unit: string,
    quantity: number,
    type: 'installation' | 'material'
  ): string => {
    const areaList = formatAreaList(areas);
    const typeLabel = type.charAt(0).toUpperCase() + type.slice(1);
    
    return `${name} ${typeLabel}\n\nAreas: ${areaList}`;
  };

  const groupAndCombineItems = (appointment: Appointment) => {
    const itemsMap = new Map<string, {
      name: string;
      price: number;
      unit: string;
      type: 'installation' | 'material';
      quantity: number;
      areas: Set<string>;
    }>();

    appointment.measurements.forEach(measurement => {
      if (!measurement.items) return;

      measurement.items.forEach(item => {
        const key = `${item.name}-${item.price}-${item.unit}-${item.type}`;
        
        const width = parseFloat(measurement.width) || 0;
        const length = parseFloat(measurement.length) || 0;
        let quantity = 0;

        if (item.unit.toLowerCase() === 'lft') {
          quantity = (width + length) * 2;
        } else if (item.unit.toLowerCase() === 'sqft') {
          quantity = width * length;
        } else {
          quantity = item.quantity;
        }

        if (itemsMap.has(key)) {
          const existingItem = itemsMap.get(key)!;
          existingItem.quantity += quantity;
          existingItem.areas.add(measurement.area);
        } else {
          itemsMap.set(key, {
            name: item.name,
            price: item.price,
            unit: item.unit,
            type: item.type,
            quantity: quantity,
            areas: new Set([measurement.area])
          });
        }
      });
    });

    return Array.from(itemsMap.values())
      .sort((a, b) => {
        if (a.type !== b.type) {
          return a.type === 'installation' ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      })
      .map(item => ({
        description: createItemDescription(
          item.name, 
          item.areas, 
          item.unit, 
          item.quantity,
          item.type
        ),
        quantity: Number(item.quantity.toFixed(2)),
        rate: item.price
      }));
  };

  const createEstimateFromFormData = async (status: 'draft' | 'pending' = 'draft') => {
    if (!user || !formData.selectedAppointment) return null;

    const appointment = appointments.find(app => app.id === formData.selectedAppointment);
    if (!appointment) return null;

    const items = groupAndCombineItems(appointment);
    const subtotal = items.reduce((sum, item) => sum + (item.quantity * item.rate), 0);
    const taxAmount = ((subtotal - formData.discount) * formData.taxRate) / 100;
    const total = subtotal - formData.discount + taxAmount;

    const estimateData = {
      user_id: user.id,
      client_id: appointment.client.id,
      title: `${appointment.title} Estimate`,
      description: `Generated from appointment on ${appointment.date}`,
      items: JSON.stringify(items),
      subtotal,
      tax_rate: formData.taxRate,
      tax_amount: taxAmount,
      discount: formData.discount,
      total,
      valid_until: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      notes: formData.notes,
      terms: formData.terms,
      measurements: JSON.stringify(appointment.measurements),
      status
    };

    return await createEstimate(estimateData);
  };

  const handleCreateEstimate = async () => {
    try {
      setLoading(true);
      setError(null);
      const newEstimate = await createEstimateFromFormData('pending');
      if (newEstimate) {
        onEstimateCreated(newEstimate);
        closeModal();
        onClose();
      }
    } catch (error) {
      console.error('Error creating AI estimate:', error);
      setError('Failed to create estimate');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveDraft = async () => {
    try {
      setSavingDraft(true);
      setError(null);
      const draftEstimate = await createEstimateFromFormData('draft');
      if (draftEstimate) {
        onEstimateCreated(draftEstimate);
        closeModal();
        onClose();
      }
    } catch (error) {
      console.error('Error saving draft estimate:', error);
      setError('Failed to save draft');
    } finally {
      setSavingDraft(false);
    }
  };

  return (
    <PersistentModal
      isOpen={isOpen}
      onClose={() => {
        closeModal();
        onClose();
      }}
      title="Create AI Estimate"
      lastSaved={lastSaved}
    >
      <div className="space-y-6">
        <Select
          label="Select Appointment"
          value={formData.selectedAppointment}
          onChange={(e) => updateFormData({ selectedAppointment: e.target.value })}
          options={[
            { value: '', label: 'Select an appointment' },
            ...appointments.map(app => ({
              value: app.id,
              label: `${app.title} - ${app.client.name}`
            }))
          ]}
        />

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tax Rate (%)
          </label>
          <input
            type="number"
            value={formData.taxRate}
            onChange={(e) => updateFormData({ taxRate: Number(parseFloat(e.target.value).toFixed(2)) })}
            step="0.01"
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Discount ($)
          </label>
          <input
            type="number"
            value={formData.discount}
            onChange={(e) => updateFormData({ discount: Number(parseFloat(e.target.value).toFixed(2)) })}
            step="0.01"
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Notes
          </label>
          <textarea
            value={formData.notes}
            onChange={(e) => updateFormData({ notes: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Terms & Conditions
          </label>
          <textarea
            value={formData.terms}
            onChange={(e) => updateFormData({ terms: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="pt-5">
          <div className="flex justify-end space-x-3">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={handleSaveDraft}
              loading={savingDraft}
              disabled={!formData.selectedAppointment || loading || savingDraft}
            >
              Save as Draft
            </Button>
            <Button
              variant="primary"
              icon={Sparkles}
              onClick={handleCreateEstimate}
              loading={loading}
              disabled={!formData.selectedAppointment || loading || savingDraft}
            >
              Generate Estimate
            </Button>
          </div>
        </div>

        {error && (
          <div className="mt-4 p-4 rounded-md bg-red-50 text-red-700">
            {error}
          </div>
        )}
      </div>
    </PersistentModal>
  );
};

export default AIEstimateModal;