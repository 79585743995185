import React from 'react';
import ShareMenu from './ShareMenu';

interface FloatingShareButtonProps {
  url: string;
  title: string;
  clientPhone?: string;
  clientEmail?: string;
  businessLogo?: string;
  businessName?: string;
}

const FloatingShareButton: React.FC<FloatingShareButtonProps> = ({ 
  url, 
  title,
  clientPhone,
  clientEmail,
  businessLogo,
  businessName
}) => {
  return (
    <div className="fixed bottom-24 sm:bottom-4 right-4 z-50">
      <ShareMenu 
        url={url} 
        title={title}
        clientPhone={clientPhone}
        clientEmail={clientEmail}
        businessLogo={businessLogo}
        businessName={businessName}
      />
    </div>
  );
};

export default FloatingShareButton;