import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FileText, FileSpreadsheet, Calendar, Users } from 'lucide-react';

const BottomNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Hide on public routes and preview routes
  if (
    ['/login', '/signup', '/'].includes(location.pathname) ||
    location.pathname.startsWith('/e/') ||
    location.pathname.startsWith('/i/')
  ) {
    return null;
  }

  const navItems = [
    { path: '/estimates', label: 'Estimates', icon: FileText },
    { path: '/invoices', label: 'Invoices', icon: FileSpreadsheet },
    { path: '/appointments', label: 'Appointments', icon: Calendar },
    { path: '/clients', label: 'Clients', icon: Users },
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 sm:hidden z-30">
      <nav className="flex justify-around">
        {navItems.map(({ path, label, icon: Icon }) => {
          const isActive = location.pathname.startsWith(path);
          return (
            <button
              key={path}
              onClick={() => navigate(path)}
              className={`flex flex-col items-center justify-center py-2 px-4 flex-1 min-w-0 ${
                isActive 
                  ? 'text-indigo-600' 
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Icon className="h-6 w-6" />
              <span className="text-xs mt-1 truncate">{label}</span>
            </button>
          );
        })}
      </nav>
    </div>
  );
};

export default BottomNavigation;