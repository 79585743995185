import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Edit, Eye } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getEstimate } from '../../lib/api/estimates';
import { getClients } from '../../lib/api/clients';
import { getProfile } from '../../lib/api/profiles';
import EstimateForm from '../../components/estimates/EstimateForm';
import EstimatePreview from '../../components/estimates/EstimatePreview';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ErrorMessage from '../../components/shared/ErrorMessage';
import BottomNavigation from '../../components/shared/BottomNavigation';

const EditEstimate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'edit' | 'preview'>('edit');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [estimate, setEstimate] = useState<any>(null);
  const [clients, setClients] = useState<any[]>([]);
  const [businessDetails, setBusinessDetails] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !id) return;
      
      try {
        const [estimateData, clientsData, profileData] = await Promise.all([
          getEstimate(id),
          getClients(user.id),
          getProfile(user.id)
        ]);

        setEstimate(estimateData);
        setClients(clientsData);
        setBusinessDetails(profileData);
      } catch (err) {
        console.error('Failed to load data:', err);
        setError('Failed to load estimate data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, user]);

  const handleTabChange = (tab: 'edit' | 'preview') => {
    if (tab === 'preview' && id) {
      navigate(`/estimates/${id}`);
    } else {
      setActiveTab(tab);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  const tabs = [
    { id: 'edit', label: 'Edit', icon: Edit },
    { id: 'preview', label: 'Preview', icon: Eye }
  ];

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="sticky top-0 z-10 bg-white border-b border-gray-200 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-4 gap-4">
          <div className="flex items-center">
            <button
              onClick={() => navigate(-1)}
              className="mr-4 text-gray-400 hover:text-gray-500"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-semibold text-gray-900">Edit Estimate</h1>
          </div>

          <nav className="flex space-x-2 sm:space-x-4 self-end sm:self-auto" aria-label="Tabs">
            {tabs.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => handleTabChange(id as 'edit' | 'preview')}
                className={`
                  inline-flex items-center px-3 py-2 text-sm font-medium rounded-md
                  ${activeTab === id
                    ? 'bg-indigo-600 text-white'
                    : 'text-gray-500 hover:text-gray-700 bg-white'
                  }
                `}
              >
                <Icon className="h-4 w-4 sm:mr-2" />
                <span className="hidden sm:inline">{label}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white rounded-lg shadow">
          {activeTab === 'edit' && (
            <div className="overflow-y-auto">
              <EstimateForm
                clients={clients}
                estimate={estimate}
                isEditing={true}
                onSuccess={() => navigate(`/estimates/${id}`)}
              />
            </div>
          )}

          {activeTab === 'preview' && (
            <EstimatePreview
              estimate={estimate}
              businessDetails={businessDetails}
            />
          )}
        </div>
      </div>

      {/* Fixed bottom navigation for mobile */}
      <div className="fixed bottom-16 left-0 right-0 bg-white border-t border-gray-200 p-4 sm:hidden">
        <div className="flex justify-between gap-4">
          <button
            onClick={() => navigate('/estimates')}
            className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            form="estimate-form"
            type="submit"
            className="flex-1 px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
          >
            Update Estimate
          </button>
        </div>
      </div>

      <BottomNavigation />
    </div>
  );
};

export default EditEstimate;