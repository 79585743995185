import React, { useRef, useEffect } from 'react';
import { Plus, Minus, Calculator } from 'lucide-react';
import Button from '../shared/Button';

interface Item {
  description: string;
  quantity: number;
  rate: number;
}

interface ItemsContainerProps {
  items: Item[];
  onChange: (items: Item[]) => void;
  onTaxRateChange: (rate: number) => void;
  onDiscountChange: (amount: number) => void;
  onNotesChange?: (notes: string) => void;
  onTermsChange?: (terms: string) => void;
  notes?: string;
  terms?: string;
  taxRate: number;
  discount: number;
  readOnly?: boolean;
}

const ItemsContainer: React.FC<ItemsContainerProps> = ({
  items,
  onChange,
  onTaxRateChange,
  onDiscountChange,
  onNotesChange,
  onTermsChange,
  notes,
  terms,
  taxRate,
  discount,
  readOnly = false
}) => {
  const lastItemRef = useRef<HTMLTableRowElement | null>(null);
  const lastItemMobileRef = useRef<HTMLDivElement | null>(null);
  const prevItemsLength = useRef(items.length);

  useEffect(() => {
    // If items were added (not removed)
    if (items.length > prevItemsLength.current) {
      // Scroll to the last item with smooth behavior
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      if (lastItemMobileRef.current) {
        lastItemMobileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    prevItemsLength.current = items.length;
  }, [items.length]);

  const handleAddItem = () => {
    onChange([...items, { description: '', quantity: 1, rate: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    onChange(items.filter((_, i) => i !== index));
  };

  const handleItemChange = (index: number, field: keyof Item, value: string | number) => {
    const newItems = [...items];
    if (field === 'quantity' || field === 'rate') {
      // Allow any valid number input, including decimals
      const numValue = typeof value === 'string' ? parseFloat(value) || 0 : value;
      newItems[index] = {
        ...newItems[index],
        [field]: numValue
      };
    } else {
      newItems[index] = {
        ...newItems[index],
        [field]: value
      };
    }
    onChange(newItems);
  };

  const calculateSubtotal = () => {
    return items.reduce((sum, item) => sum + (item.quantity * item.rate), 0);
  };

  const calculateTaxAmount = (subtotal: number) => {
    return ((subtotal - discount) * taxRate) / 100;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const taxAmount = calculateTaxAmount(subtotal);
    return subtotal - discount + taxAmount;
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Items</h3>
        <div className="flex space-x-2">
          {!readOnly && (
            <Button
              type="button"
              variant="secondary"
              size="sm"
              icon={Plus}
              onClick={handleAddItem}
            >
              Add Item
            </Button>
          )}
        </div>
      </div>

      {/* Mobile Items List */}
      <div className="block lg:hidden">
        {items.map((item, index) => {
          const isLastItem = index === items.length - 1;
          return (
            <div 
              key={index} 
              ref={isLastItem ? lastItemMobileRef : null}
              className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-4"
            >
              <div className="space-y-3">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <input
                    type="text"
                    value={item.description}
                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Item description"
                    readOnly={readOnly}
                  />
                </div>
                
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      readOnly={readOnly}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Rate</label>
                    <input
                      type="number"
                      value={item.rate}
                      onChange={(e) => handleItemChange(index, 'rate', e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center pt-2 border-t border-gray-200">
                  <span className="text-sm font-medium text-gray-500">Amount:</span>
                  <div className="flex items-center space-x-3">
                    <span className="text-sm font-medium text-gray-900">
                      ${(item.quantity * item.rate).toFixed(2)}
                    </span>
                    {!readOnly && (
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <Minus className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Desktop Items Table */}
      <div className="hidden lg:block overflow-hidden rounded-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Quantity
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rate
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              {!readOnly && (
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {items.map((item, index) => {
              const isLastItem = index === items.length - 1;
              return (
                <tr 
                  key={index}
                  ref={isLastItem ? lastItemRef : null}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      value={item.description}
                      onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                      className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                      placeholder="Item description"
                      readOnly={readOnly}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      className="block w-20 ml-auto border-0 p-0 text-right text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                      readOnly={readOnly}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <input
                      type="number"
                      value={item.rate}
                      onChange={(e) => handleItemChange(index, 'rate', e.target.value)}
                      className="block w-24 ml-auto border-0 p-0 text-right text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                      readOnly={readOnly}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                    ${(item.quantity * item.rate).toFixed(2)}
                  </td>
                  {!readOnly && (
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <Minus className="h-5 w-5" />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {items.length === 0 && (
        <p className="text-center py-4 text-gray-500">
          No items added yet. Click "Add Item" to start.
        </p>
      )}

      {/* Notes & Terms */}
      {!readOnly && (
        <div className="space-y-4">
          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              id="notes"
              value={notes}
              onChange={(e) => onNotesChange?.(e.target.value)}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="terms" className="block text-sm font-medium text-gray-700">
              Terms & Conditions
            </label>
            <textarea
              id="terms"
              value={terms}
              onChange={(e) => onTermsChange?.(e.target.value)}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      )}

      {/* Totals Section */}
      {items.length > 0 && (
        <div className="mt-6 bg-gray-50 rounded-lg p-4">
          <div className="space-y-3">
            <div className="flex justify-end items-center text-sm">
              <span className="text-gray-500">Subtotal:</span>
              <span className="ml-4 text-gray-900 font-medium">
                ${calculateSubtotal().toFixed(2)}
              </span>
            </div>

            {!readOnly && (
              <div className="flex justify-end items-center text-sm">
                <label className="text-gray-500">Discount:</label>
                <div className="ml-4 w-32">
                  <input
                    type="number"
                    value={discount}
                    onChange={(e) => onDiscountChange(Number(e.target.value))}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    min="0"
                    placeholder="0.00"
                  />
                </div>
              </div>
            )}

            {discount > 0 && readOnly && (
              <div className="flex justify-end items-center text-sm">
                <span className="text-gray-500">Discount:</span>
                <span className="ml-4 text-gray-900 font-medium">
                  -${discount.toFixed(2)}
                </span>
              </div>
            )}

            {!readOnly && (
              <div className="flex justify-end items-center text-sm">
                <label className="text-gray-500">Tax Rate (%):</label>
                <div className="ml-4 w-32">
                  <input
                    type="number"
                    value={taxRate}
                    onChange={(e) => onTaxRateChange(Number(e.target.value))}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    min="0"
                    placeholder="0.0"
                  />
                </div>
              </div>
            )}

            <div className="flex justify-end items-center text-sm">
              <span className="text-gray-500">Tax ({taxRate}%):</span>
              <span className="ml-4 text-gray-900 font-medium">
                ${calculateTaxAmount(calculateSubtotal()).toFixed(2)}
              </span>
            </div>

            <div className="pt-3 border-t border-gray-200">
              <div className="flex justify-end items-center">
                <span className="text-base font-medium text-gray-900">Total:</span>
                <span className="ml-4 text-lg font-bold text-indigo-600">
                  ${calculateTotal().toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemsContainer;