import React, { useState } from 'react';
import { Grid, Plus, Minus, Calculator } from 'lucide-react';
import Button from '../shared/Button';
import AddMeasurementModal from './AddMeasurementModal';
import EditMeasurementModal from './EditMeasurementModal';
import { generateFloorPlanFromMeasurements } from '../../utils/floorPlan';
import type { Measurement } from '../../types/appointments';

interface MeasurementFormProps {
  measurements: Measurement[];
  onChange: (measurements: Measurement[]) => void;
  clientId?: string;
  readOnly?: boolean;
}

const MeasurementForm: React.FC<MeasurementFormProps> = ({
  measurements = [],
  onChange,
  clientId,
  readOnly = false
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingMeasurement, setEditingMeasurement] = useState<{
    index: number;
    measurement: Measurement;
  } | null>(null);
  const [floorPlan, setFloorPlan] = useState<string | null>(null);
  const [generating, setGenerating] = useState(false);

  const handleAddMeasurement = (measurement: Measurement) => {
    onChange([...measurements, measurement]);
    setShowAddModal(false);
  };

  const handleEditMeasurement = (measurement: Measurement) => {
    if (editingMeasurement === null) return;
    const newMeasurements = [...measurements];
    newMeasurements[editingMeasurement.index] = measurement;
    onChange(newMeasurements);
    setEditingMeasurement(null);
  };

  const handleRemoveMeasurement = (index: number) => {
    onChange(measurements.filter((_, i) => i !== index));
  };

  const calculateTotalSquareFootage = () => {
    return measurements.reduce((total, m) => {
      const width = parseFloat(m.width) || 0;
      const length = parseFloat(m.length) || 0;
      return total + (width * length);
    }, 0);
  };

  const handleGenerateFloorPlan = async () => {
    try {
      setGenerating(true);
      const floorPlanImage = generateFloorPlanFromMeasurements(measurements);
      setFloorPlan(floorPlanImage);
    } catch (error) {
      console.error('Error generating floor plan:', error);
    } finally {
      setGenerating(false);
    }
  };

  const handleDownloadFloorPlan = () => {
    if (!floorPlan) return;

    const link = document.createElement('a');
    link.href = floorPlan;
    link.download = 'floor-plan.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="space-y-4 pb-32 sm:pb-0"> {/* Added padding bottom for mobile */}
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Measurements</h3>
        {!readOnly && (
          <div className="flex space-x-2">
            {measurements.length > 0 && (
              <Button
                type="button"
                variant="secondary"
                size="sm"
                icon={Grid}
                onClick={handleGenerateFloorPlan}
                loading={generating}
              >
                Generate Floor Plan
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              size="sm"
              icon={Plus}
              onClick={() => setShowAddModal(true)}
            >
              Add Area
            </Button>
          </div>
        )}
      </div>

      {measurements.map((measurement, index) => {
        const width = parseFloat(measurement.width) || 0;
        const length = parseFloat(measurement.length) || 0;
        const sqft = width * length;

        return (
          <div key={index} className="border border-gray-200 rounded-lg p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Area</label>
                <p className="mt-1">{measurement.area}</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Width (ft)</label>
                <p className="mt-1">{measurement.width}</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Length (ft)</label>
                <p className="mt-1">{measurement.length}</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Square Footage</label>
                <div className="mt-1 flex items-center justify-between">
                  <span className="text-gray-900">
                    {sqft.toFixed(2)} sq ft
                  </span>
                  {!readOnly && (
                    <div className="flex space-x-2">
                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        icon={Plus}
                        onClick={() => setEditingMeasurement({ index, measurement })}
                        className="!p-2"
                      >
                        <span className="sr-only">Edit</span>
                      </Button>
                      <button
                        type="button"
                        onClick={() => handleRemoveMeasurement(index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <Minus className="h-5 w-5" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {measurement.notes && (
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Notes</label>
                <p className="mt-1 text-gray-600">{measurement.notes}</p>
              </div>
            )}

            {measurement.items && measurement.items.length > 0 && (
              <div className="mt-4 border-t border-gray-200 pt-4">
                <h4 className="text-sm font-medium text-gray-900 mb-2">Selected Items</h4>
                <div className="space-y-2">
                  {measurement.items.map((item, itemIndex) => (
                    <div key={itemIndex} className="flex justify-between items-center text-sm">
                      <span className="text-gray-600">
                        {item.name} ({item.quantity} x {item.price}/{item.unit})
                      </span>
                      <span className="font-medium">
                        ${(item.quantity * item.price).toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}

      {measurements.length === 0 && (
        <p className="text-center text-gray-500 py-4">
          No measurements added yet. Click "Add Area" to start.
        </p>
      )}

      {measurements.length > 0 && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Calculator className="h-5 w-5 text-gray-400 mr-2" />
              <span className="text-sm font-medium text-gray-700">Total Square Footage:</span>
            </div>
            <span className="text-lg font-bold text-indigo-600">
              {calculateTotalSquareFootage().toFixed(2)} sq ft
            </span>
          </div>
        </div>
      )}

      {showAddModal && (
        <AddMeasurementModal
          onClose={() => setShowAddModal(false)}
          onAdd={handleAddMeasurement}
        />
      )}

      {editingMeasurement && (
        <EditMeasurementModal
          measurement={editingMeasurement.measurement}
          onClose={() => setEditingMeasurement(null)}
          onSave={handleEditMeasurement}
        />
      )}

      {/* Floor Plan Display */}
      {floorPlan && (
        <div className="mt-6 border-t border-gray-200 pt-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">Floor Plan</h3>
            <Button
              variant="secondary"
              size="sm"
              icon={Grid}
              onClick={handleDownloadFloorPlan}
            >
              Download Floor Plan
            </Button>
          </div>
          <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
            <img
              src={floorPlan}
              alt="Floor Plan"
              className="w-full h-auto object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeasurementForm;