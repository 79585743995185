import React, { useState } from 'react';
import { Mail, Lock, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Input from '../shared/Input';
import Button from '../shared/Button';
import Select from '../shared/Select';
import { SUPPORTED_LANGUAGES } from '../../utils/languages';

interface AuthFormProps {
  type?: 'login' | 'signup';
  onSubmit: (data: { email: string; password: string; name?: string; language?: string }) => void;
  loading?: boolean;
}

const AuthForm: React.FC<AuthFormProps> = ({ type = 'login', onSubmit, loading }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    language: 'en'
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (type === 'signup') {
      onSubmit(formData);
    } else {
      onSubmit({ email: formData.email, password: formData.password });
    }
  };

  return (
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      {type === 'login' && (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            {t('auth.signIn')}
          </h2>
        </div>
      )}

      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="space-y-4">
          {type === 'signup' && (
            <Input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              label={t('auth.name')}
              icon={User}
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          )}
          
          <Input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            label={t('auth.email')}
            icon={Mail}
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete={type === 'signup' ? 'new-password' : 'current-password'}
            required
            label={t('auth.password')}
            icon={Lock}
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />

          {type === 'signup' && (
            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-700">
                {t('auth.language')}
              </label>
              <Select
                id="language"
                value={formData.language}
                onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                options={SUPPORTED_LANGUAGES.map(lang => ({
                  value: lang.code,
                  label: `${lang.flag} ${lang.name}`
                }))}
              />
            </div>
          )}
        </div>

        <div className="space-y-4">
          <Button
            type="submit"
            variant="primary"
            className="w-full"
            loading={loading}
          >
            {type === 'signup' ? 'Sign Up' : t('auth.signIn')}
          </Button>

          {type === 'signup' && (
            <div className="text-center">
              <p className="text-sm text-gray-600">
                {t('auth.alreadyHaveAccount')}{' '}
                <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                  {t('auth.signIn')}
                </Link>
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default AuthForm;