import React from 'react';
import BusinessHeader from './preview/BusinessHeader';
import ClientInfo from './preview/ClientInfo';
import ItemsTable from './preview/ItemsTable';
import NotesAndTerms from './preview/NotesAndTerms';
import FloorPlanSection from './preview/FloorPlanSection';
import type { Measurement } from '../../types/appointments';

interface EstimatePreviewProps {
  estimate: any;
  businessDetails: any;
  isInvoice?: boolean;
  themeColor?: string;
}

const EstimatePreview: React.FC<EstimatePreviewProps> = ({ 
  estimate, 
  businessDetails,
  isInvoice = false,
  themeColor = 'bg-indigo-600'
}) => {
  const parsedItems = React.useMemo(() => {
    if (!estimate?.items) return [];
    
    try {
      if (typeof estimate.items === 'string') {
        const parsed = JSON.parse(estimate.items);
        return Array.isArray(parsed) ? parsed : [];
      }
      return Array.isArray(estimate.items) ? estimate.items : [];
    } catch (err) {
      console.error('Error parsing items:', err);
      return [];
    }
  }, [estimate?.items]);

  const parsedMeasurements = React.useMemo(() => {
    if (!estimate?.measurements) return [];
    
    try {
      if (typeof estimate.measurements === 'string') {
        const parsed = JSON.parse(estimate.measurements);
        return Array.isArray(parsed) ? parsed : [];
      }
      return Array.isArray(estimate.measurements) ? estimate.measurements : [];
    } catch (err) {
      console.error('Error parsing measurements:', err);
      return [];
    }
  }, [estimate?.measurements]);

  if (!estimate) {
    return (
      <div className="flex items-center justify-center p-8">
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }

  return (
    <div 
      id={isInvoice ? 'invoice-content' : 'estimate-content'} 
      className="bg-white w-full max-w-[210mm] mx-auto print:max-w-none rounded-[20px] shadow-[0_4px_24px_rgba(0,0,0,0.1)] overflow-hidden border border-black/10"
    >
      <div className="divide-y divide-gray-200">
        <BusinessHeader 
          businessDetails={businessDetails} 
          document={estimate}
          isInvoice={isInvoice}
          themeColor={themeColor}
        />
        
        <div>
          <ClientInfo
            client={estimate.client}
            title={estimate.title}
            description={estimate.description}
          />
        </div>
        
        <div>
          <ItemsTable
            items={parsedItems}
            subtotal={parseFloat(estimate.subtotal) || 0}
            taxRate={parseFloat(estimate.tax_rate) || 0}
            taxAmount={parseFloat(estimate.tax_amount) || 0}
            total={parseFloat(estimate.total) || 0}
            discount={parseFloat(estimate.discount) || 0}
            themeColor={themeColor}
          />
        </div>

        {parsedMeasurements.length > 0 && (
          <div>
            <FloorPlanSection 
              floorPlans={parsedMeasurements}
            />
          </div>
        )}

        <div>
          <NotesAndTerms
            notes={estimate.notes}
            terms={estimate.terms}
          />
        </div>
      </div>
    </div>
  );
};

export default EstimatePreview;