import React, { useState, useEffect } from 'react';
import { X, Plus, Trash2, Calculator } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getPricingItems } from '../../lib/api/pricing';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import { formatCurrency } from '../../utils/currency';
import { formatUnit } from '../../utils/pricing';
import type { PricingItem } from '../../types/pricing';
import { useModalPersistence } from '../../hooks/useModalPersistence';
import PersistentModal from '../shared/PersistentModal';

const HOUSE_AREAS = [
  'Living Room',
  'Master Bedroom',
  'Bedroom 2',
  'Bedroom 3',
  'Kitchen',
  'Dining Room',
  'Bathroom 1',
  'Bathroom 2',
  'Hallway',
  'Stairs',
  'Basement',
  'Attic',
  'Office',
  'Family Room',
  'Laundry Room',
  'Garage',
  'Other'
];

interface SelectedPricingItem extends PricingItem {
  quantity: number;
}

interface AddMeasurementModalProps {
  onClose: () => void;
  onAdd: (measurement: { 
    area: string; 
    width: string; 
    length: string; 
    notes: string;
    items?: Array<{
      id: string;
      name: string;
      price: number;
      unit: string;
      type: 'installation' | 'material';
      quantity: number;
    }>;
  }) => void;
}

const AddMeasurementModal: React.FC<AddMeasurementModalProps> = ({ onClose, onAdd }) => {
  const { user } = useAuth();
  const {
    isOpen,
    formData,
    lastSaved,
    updateFormData,
    closeModal
  } = useModalPersistence('add-measurement', {
    area: '',
    width: '',
    length: '',
    notes: '',
    selectedItems: []
  });

  const [selectedItems, setSelectedItems] = useState<SelectedPricingItem[]>([]);
  const [installationItems, setInstallationItems] = useState<PricingItem[]>([]);
  const [materialItems, setMaterialItems] = useState<PricingItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchItems();
  }, [user]);

  const fetchItems = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const [installation, materials] = await Promise.all([
        getPricingItems(user.id, 'installation'),
        getPricingItems(user.id, 'material')
      ]);
      setInstallationItems(installation);
      setMaterialItems(materials);
    } catch (err) {
      console.error('Error fetching pricing items:', err);
      setError('Failed to load pricing items');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!formData.area || !formData.width || !formData.length) {
      return;
    }
    onAdd({
      ...formData,
      items: selectedItems.map(item => ({
        id: item.id,
        name: item.name,
        price: item.price,
        unit: item.unit,
        type: item.type,
        quantity: item.quantity
      }))
    });
    closeModal();
    onClose();
  };

  const handleAddInstallationItem = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const item = installationItems.find(i => i.id === e.target.value);
    if (item) {
      setSelectedItems(prev => [...prev, { ...item, quantity: 1 }]);
    }
    e.target.value = '';
  };

  const handleAddMaterialItem = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const item = materialItems.find(i => i.id === e.target.value);
    if (item) {
      setSelectedItems(prev => [...prev, { ...item, quantity: 1 }]);
    }
    e.target.value = '';
  };

  const handleRemoveItem = (itemId: string) => {
    setSelectedItems(prev => prev.filter(item => item.id !== itemId));
  };

  const handleQuantityChange = (itemId: string, quantity: number) => {
    setSelectedItems(prev => 
      prev.map(item => 
        item.id === itemId 
          ? { ...item, quantity: Math.max(0.01, quantity) }
          : item
      )
    );
  };

  const calculateTotalCost = () => {
    return selectedItems.reduce((total, item) => {
      const sqft = parseFloat(formData.width) * parseFloat(formData.length);
      const quantity = item.unit.toLowerCase() === 'lft' ? sqft / 3 : sqft;
      return total + (item.price * quantity);
    }, 0);
  };

  return (
    <PersistentModal
      isOpen={isOpen}
      onClose={() => {
        closeModal();
        onClose();
      }}
      title="Add Area"
      lastSaved={lastSaved}
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Select
            label="Area"
            value={formData.area}
            onChange={(e) => updateFormData({ area: e.target.value })}
            options={[
              { value: '', label: 'Select Area' },
              ...HOUSE_AREAS.map(area => ({ value: area, label: area }))
            ]}
            required
          />

          <div className="grid grid-cols-2 gap-4">
            <Input
              label="Width (ft)"
              type="number"
              value={formData.width}
              onChange={(e) => updateFormData({ width: e.target.value })}
              required
              step="0.1"
            />

            <Input
              label="Length (ft)"
              type="number"
              value={formData.length}
              onChange={(e) => updateFormData({ length: e.target.value })}
              required
              step="0.1"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              value={formData.notes}
              onChange={(e) => updateFormData({ notes: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Additional notes about this area..."
            />
          </div>

          {/* Pricing Items Section */}
          <div className="border-t border-gray-200 pt-4">
            <h4 className="text-sm font-medium text-gray-900 mb-4">Add Items</h4>
            
            {/* Installation Items */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Installation Items
              </label>
              <select
                onChange={handleAddInstallationItem}
                value=""
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Installation Item</option>
                {installationItems
                  .filter(item => !selectedItems.some(si => si.id === item.id))
                  .map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name} ({formatCurrency(item.price)}/{formatUnit(item.unit)})
                    </option>
                  ))
                }
              </select>
            </div>

            {/* Material Items */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Material Items
              </label>
              <select
                onChange={handleAddMaterialItem}
                value=""
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Material Item</option>
                {materialItems
                  .filter(item => !selectedItems.some(si => si.id === item.id))
                  .map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name} ({formatCurrency(item.price)}/{formatUnit(item.unit)})
                    </option>
                  ))
                }
              </select>
            </div>

            {/* Selected Items */}
            {selectedItems.length > 0 && (
              <div className="mt-4">
                <h5 className="text-sm font-medium text-gray-700 mb-2">Selected Items</h5>
                <div className="space-y-2 max-h-[40vh] overflow-y-auto overscroll-contain rounded-md">
                  {selectedItems.map((item) => (
                    <div 
                      key={item.id} 
                      className="flex items-center justify-between bg-gray-50 p-3 rounded-md"
                    >
                      <div className="flex-grow min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">{item.name}</p>
                        <p className="text-xs text-gray-500">
                          {formatCurrency(item.price)}/{formatUnit(item.unit)} ({item.type})
                        </p>
                      </div>
                      <div className="flex items-center space-x-3 ml-4">
                        <div className="flex items-center space-x-2">
                          <label className="text-sm text-gray-500 whitespace-nowrap">Qty:</label>
                          <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) => handleQuantityChange(item.id, parseFloat(e.target.value))}
                            min="0.01"
                            step="0.01"
                            className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <Button
                          variant="secondary"
                          size="sm"
                          icon={Trash2}
                          onClick={() => handleRemoveItem(item.id)}
                          className="!p-2 text-red-600 hover:text-red-700"
                        >
                          <span className="sr-only">Remove</span>
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Total Cost */}
                <div className="mt-4 pt-4 border-t border-gray-200">
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center text-gray-700">
                      <Calculator className="h-4 w-4 mr-2" />
                      Total Cost:
                    </div>
                    <span className="font-medium text-gray-900">
                      {formatCurrency(calculateTotalCost())}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              closeModal();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={handleSubmit}
          >
            Add Area
          </Button>
        </div>
      </div>
    </PersistentModal>
  );
};

export default AddMeasurementModal;