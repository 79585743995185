import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthForm from '../../components/auth/AuthForm';
import { useAuth } from '../../contexts/AuthContext';
import ErrorMessage from '../../components/shared/ErrorMessage';

const SignupPage: React.FC = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Get session_id from URL if present
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (sessionId) {
      console.log('Payment successful, session ID:', sessionId);
    }
  }, [sessionId]);

  const handleSignup = async (data: { 
    email: string; 
    password: string; 
    name?: string;
    language?: string;
  }) => {
    if (!data.name) {
      setError('Name is required');
      return;
    }

    try {
      setError(null);
      setLoading(true);
      await signUp(data.email, data.password, data.name, data.language);
      navigate('/dashboard');
    } catch (err: any) {
      console.error('Signup error:', err);
      if (err.message === 'User already registered') {
        setError('An account with this email already exists. Please try logging in instead.');
      } else {
        setError(err.message || 'Failed to create account. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-md mx-auto pt-8 px-4">
        {error && <ErrorMessage message={error} />}
        <AuthForm type="signup" onSubmit={handleSignup} loading={loading} />
      </div>
    </div>
  );
};

export default SignupPage;