import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Calendar, Ruler, Grid, FileText, Calculator, FileSpreadsheet, Share2, Download, Smartphone } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import StripePricingTable from '../components/pricing/StripePricingTable';
import ImageCarousel from '../components/landing/ImageCarousel';
import VideoSection from '../components/landing/VideoSection';
import HeroSection from '../components/landing/HeroSection';
import CTASection from '../components/landing/CTASection';
import WorkflowStep from '../components/landing/WorkflowStep';
import { workflowSteps } from '../components/landing/WorkflowImages';

const LandingPage: React.FC = () => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const showPricing = searchParams.get('upgrade') === 'true' || searchParams.get('pricing') === 'true';

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDownloadAPK = () => {
    window.location.href = 'https://evfmomsostwjckeloxux.supabase.co/storage/v1/object/public/APK/Version%201%20EstimadoPro.apk';
  };

  const features = [
    {
      icon: Calendar,
      title: "Easy Appointments",
      description: "Schedule and manage client appointments effortlessly with our intuitive calendar interface",
      color: "bg-blue-500"
    },
    {
      icon: Ruler,
      title: "Smart Measurements",
      description: "Record measurements with automatic square footage calculation - no more manual math",
      color: "bg-green-500"
    },
    {
      icon: Grid,
      title: "AI Floor Plans",
      description: "Generate professional floor plans instantly from your measurements with our AI technology",
      color: "bg-purple-500"
    },
    {
      icon: FileText,
      title: "Beautiful Estimates",
      description: "Create polished estimates with integrated floor plans and professional formatting",
      color: "bg-red-500"
    },
    {
      icon: FileSpreadsheet,
      title: "Invoice Generation",
      description: "Convert estimates to invoices with one click and track payments easily",
      color: "bg-orange-500"
    },
    {
      icon: Calculator,
      title: "Automatic Calculations",
      description: "Get instant totals, tax calculations, and material estimates automatically",
      color: "bg-teal-500"
    },
    {
      icon: Share2,
      title: "Easy Sharing",
      description: "Share estimates and invoices instantly via email, SMS, or download as PDF",
      color: "bg-indigo-500"
    },
    {
      icon: Grid,
      title: "Multi-Room Support",
      description: "Handle multiple rooms and areas with ease - perfect for large projects",
      color: "bg-pink-500"
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <HeroSection user={user} onGetStarted={scrollToPricing} />

      {/* Android App Download Section */}
      <div className="bg-gradient-to-b from-indigo-50 to-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="flex flex-col items-center">
            <Smartphone className="h-16 w-16 text-indigo-600 mb-4" />
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Get EstimadoPro for Android
            </h2>
            <p className="text-lg text-gray-600 mb-8 max-w-2xl">
              Download our Android app and take EstimadoPro with you wherever you go. Create estimates and manage your business on the move.
            </p>
            <button
              onClick={handleDownloadAPK}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              <Download className="h-5 w-5 mr-2" />
              Download Android App
            </button>
            <p className="mt-4 text-sm text-gray-500">
              Compatible with Android 6.0 and above
            </p>
          </div>
        </div>
      </div>

      {/* Video Section */}
      <VideoSection />

      {/* Feature Showcase */}
      <div className="bg-gray-50 py-16 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center mb-12">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Feature Showcase</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              See EstimadoPro in Action
            </p>
            <p className="mt-4 text-lg text-gray-600">
              Take a tour through our intuitive interface and powerful features
            </p>
          </div>
          <ImageCarousel />
          
          {/* Key Features Grid */}
          <div className="mt-24">
            <h3 className="text-2xl font-bold text-gray-900 text-center mb-12">
              Everything you need to streamline your business
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="relative group">
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                    <div className="relative bg-white p-6 rounded-lg shadow-sm ring-1 ring-gray-900/5 space-y-3">
                      <div className={`inline-flex p-3 rounded-lg ${feature.color}`}>
                        <Icon className="h-6 w-6 text-white" />
                      </div>
                      <h4 className="text-lg font-semibold text-gray-900">
                        {feature.title}
                      </h4>
                      <p className="text-sm text-gray-600">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Workflow Steps */}
      {workflowSteps.map((step, index) => (
        <WorkflowStep key={index} step={step} index={index} />
      ))}

      {/* Pricing Section */}
      <div id="pricing" className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Choose the right plan for your business
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Select a plan that best fits your needs. Start managing your estimates professionally today.
          </p>

          <div className="mt-16">
            <StripePricingTable className="mx-auto max-w-5xl" />
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <CTASection user={user} onGetStarted={scrollToPricing} />
    </div>
  );
};

export default LandingPage;