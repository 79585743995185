import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { createClient, updateClient } from '../../lib/api/clients';
import Button from '../shared/Button';
import Input from '../shared/Input';
import ContactPicker from '../shared/ContactPicker';
import AddressFields from './AddressFields';
import type { Client, ClientFormData, ImportedContact } from '../../types/clients';

interface ClientModalProps {
  client?: Client;
  onClose: () => void;
  userId?: string;
  onSuccess?: (client: Client) => void;
  importedContact?: ImportedContact;
}

const ClientModal: React.FC<ClientModalProps> = ({ 
  client, 
  onClose, 
  userId,
  onSuccess,
  importedContact 
}) => {
  const [formData, setFormData] = useState<ClientFormData>({
    name: client?.name || '',
    company: client?.company || '',
    email: client?.email || '',
    phone: client?.phone || '',
    street_address: client?.street_address || '',
    city: client?.city || '',
    state: client?.state || '',
    zip: client?.zip || '',
    country: client?.country || ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  useEffect(() => {
    if (importedContact) {
      setFormData({
        name: importedContact.name || '',
        company: importedContact.company || '',
        email: importedContact.email || '',
        phone: importedContact.phone || '',
        street_address: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      });
    }
  }, [importedContact]);

  const handleContactSelect = (contact: ImportedContact) => {
    setFormData({
      name: contact.name || '',
      company: contact.company || '',
      email: contact.email || '',
      phone: contact.phone || '',
      street_address: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    });
  };

  const handleAddressChange = (field: string, value: string) => {
    const fieldMap: Record<string, keyof ClientFormData> = {
      street: 'street_address',
      city: 'city',
      state: 'state',
      zip: 'zip',
      country: 'country'
    };

    setFormData(prev => ({
      ...prev,
      [fieldMap[field]]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userId) return;

    try {
      setSubmitting(true);
      setError(null);

      // Only require name field
      if (!formData.name.trim()) {
        setError('Name is required');
        return;
      }

      if (client) {
        const updatedClient = await updateClient(client.id, formData);
        onSuccess?.(updatedClient);
      } else {
        const newClient = await createClient({
          ...formData,
          user_id: userId,
        });
        onSuccess?.(newClient);
      }

      onClose();
    } catch (err: any) {
      console.error('Failed to save client:', err);
      if (err.code === '23505') {
        setError('A client with this email already exists');
      } else if (err.message) {
        setError(err.message);
      } else {
        setError('Failed to save client');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={handleBackdropClick}>
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              onClick={handleClose}
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {client ? 'Edit Client' : 'Add New Client'}
              </h3>

              {!client && (
                <div className="mt-4">
                  <ContactPicker onContactSelect={handleContactSelect} />
                </div>
              )}

              {error && (
                <div className="mt-2 p-2 text-sm text-red-600 bg-red-100 rounded">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit} className="mt-4 space-y-6">
                <div className="space-y-4">
                  <Input
                    label="Full Name"
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    required
                  />

                  <Input
                    label="Company (Optional)"
                    type="text"
                    value={formData.company}
                    onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                  />

                  <Input
                    label="Email (Optional)"
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />

                  <Input
                    label="Phone (Optional)"
                    type="tel"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  />
                </div>

                <div className="border-t border-gray-200 pt-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-4">Address Information (Optional)</h4>
                  <AddressFields
                    address={{
                      street: formData.street_address,
                      city: formData.city,
                      state: formData.state,
                      zip: formData.zip,
                      country: formData.country
                    }}
                    onChange={handleAddressChange}
                  />
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    type="submit"
                    variant="primary"
                    loading={submitting}
                    disabled={submitting}
                    className="w-full sm:ml-3 sm:w-auto"
                  >
                    {client ? 'Update Client' : 'Add Client'}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handleClose}
                    className="mt-3 w-full sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientModal;