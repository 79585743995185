import { z } from 'zod';

const envSchema = z.object({
  VITE_SUPABASE_URL: z.string().url('Invalid Supabase URL'),
  VITE_SUPABASE_ANON_KEY: z.string().min(1, 'Supabase anon key is required'),
  VITE_STRIPE_PUBLISHABLE_KEY: z.string().min(1, 'Stripe publishable key is required'),
  VITE_STRIPE_PRICING_TABLE_ID: z.string().min(1, 'Stripe pricing table ID is required'),
  NODE_ENV: z.enum(['development', 'production', 'test']).default('development')
});

export type EnvConfig = z.infer<typeof envSchema>;

export function validateEnv(): EnvConfig {
  try {
    const config = {
      VITE_SUPABASE_URL: import.meta.env.VITE_SUPABASE_URL,
      VITE_SUPABASE_ANON_KEY: import.meta.env.VITE_SUPABASE_ANON_KEY,
      VITE_STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
      VITE_STRIPE_PRICING_TABLE_ID: import.meta.env.VITE_STRIPE_PRICING_TABLE_ID,
      NODE_ENV: import.meta.env.NODE_ENV
    };

    // Validate environment variables
    const result = envSchema.safeParse(config);

    if (!result.success) {
      console.error('Environment validation failed:', result.error.format());
      throw new Error('Invalid environment configuration. Check your .env file.');
    }

    return result.data;
  } catch (error) {
    console.error('Environment validation failed:', error);
    throw new Error('Failed to validate environment configuration');
  }
}

// Validate environment variables immediately
validateEnv();