import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InvoiceList from './invoices/InvoiceList';
import NewInvoice from './invoices/NewInvoice';
import ViewInvoice from './invoices/ViewInvoice';
import EditInvoice from './invoices/EditInvoice';
import BottomNavigation from '../components/shared/BottomNavigation';

const Invoices: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <Routes>
        <Route path="/" element={<InvoiceList />} />
        <Route path="/new" element={<NewInvoice />} />
        <Route path="/:id" element={<ViewInvoice />} />
        <Route path="/edit/:id" element={<EditInvoice />} />
      </Routes>
      <BottomNavigation />
    </div>
  );
};

export default Invoices;