export const PDF_UNITS = {
  POINTS: 'pt',
  MM: 'mm',
  INCHES: 'in'
} as const;

export const PAGE_SIZES = {
  A4: 'a4',
  LETTER: 'letter'
} as const;

export const PAGE_ORIENTATIONS = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape'
} as const;

// A4 dimensions in points (1 pt = 1/72 inch)
export const A4_DIMENSIONS = {
  WIDTH: 595.28,  // 8.27 inches * 72
  HEIGHT: 841.89  // 11.69 inches * 72
} as const;

// Default margins in points
export const DEFAULT_MARGINS = {
  DESKTOP: 72,    // 1 inch
  MOBILE: 36      // 0.5 inch
} as const;

// Image quality settings
export const IMAGE_SETTINGS = {
  DESKTOP: {
    QUALITY: 1.0,
    COMPRESSION: 'NONE'
  },
  MOBILE: {
    QUALITY: 0.8,
    COMPRESSION: 'MEDIUM'
  }
} as const;