import React from 'react';

interface NotesAndTermsProps {
  notes?: string;
  terms?: string;
}

const NotesAndTerms: React.FC<NotesAndTermsProps> = ({ notes, terms }) => {
  if (!notes && !terms) return null;

  return (
    <div className="px-4 sm:px-8 py-4 sm:py-6 space-y-4 sm:space-y-6" data-section="notes-terms">
      {notes && (
        <div>
          <h4 className="text-sm font-medium text-gray-900 mb-2">Notes</h4>
          <p className="text-xs sm:text-sm text-gray-600 whitespace-pre-wrap bg-gray-50 rounded-lg p-4">{notes}</p>
        </div>
      )}
      {terms && (
        <div>
          <h4 className="text-sm font-medium text-gray-900 mb-2">Terms & Conditions</h4>
          <p className="text-xs sm:text-sm text-gray-600 whitespace-pre-wrap bg-gray-50 rounded-lg p-4">{terms}</p>
        </div>
      )}
    </div>
  );
};

export default NotesAndTerms;