export const formatUnit = (unit: string): string => {
  switch (unit.toLowerCase()) {
    case 'sqft':
      return 'sq ft';
    case 'lft':
      return 'ln ft';
    case 'each':
      return 'ea';
    default:
      return unit;
  }
};

export const PRICING_UNITS = [
  { value: 'sqft', label: 'Square Feet (sq ft)' },
  { value: 'lft', label: 'Linear Feet (ln ft)' },
  { value: 'each', label: 'Each (ea)' }
] as const;

export type PricingUnit = typeof PRICING_UNITS[number]['value'];

export const isAreaBasedUnit = (unit: string): boolean => {
  return ['sqft', 'lft'].includes(unit.toLowerCase());
};