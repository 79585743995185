import React from 'react';
import { Grid, Trash2 } from 'lucide-react';
import Button from '../../shared/Button';
import { generateFloorPlanFromMeasurements } from '../../../utils/floorPlan';
import type { Measurement } from '../../../types/appointments';

interface FloorPlanSectionProps {
  floorPlans: Measurement[];
  onRemoveFloorPlan?: (index: number) => void;
  className?: string;
}

const FloorPlanSection: React.FC<FloorPlanSectionProps> = ({ 
  floorPlans = [], 
  onRemoveFloorPlan,
  className = ''
}) => {
  if (!Array.isArray(floorPlans) || floorPlans.length === 0) return null;

  const totalArea = floorPlans.reduce((total, plan) => {
    const width = parseFloat(plan.width) || 0;
    const length = parseFloat(plan.length) || 0;
    return total + (width * length);
  }, 0);

  const handleRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRemoveFloorPlan) {
      onRemoveFloorPlan(index);
    }
  };

  return (
    <div className={`px-4 sm:px-8 py-6 border-t border-gray-200 ${className}`} data-section="floor-plans">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium text-gray-900">Floor Plans</h2>
        <p className="text-sm text-gray-500">
          Total Area: {totalArea.toFixed(2)} sq ft
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {floorPlans.map((plan, index) => {
          const image = generateFloorPlanFromMeasurements([plan]);
          const sqft = (parseFloat(plan.width) * parseFloat(plan.length)).toFixed(2);

          return (
            <div key={index} className="relative bg-gray-50 rounded-lg p-4">
              {onRemoveFloorPlan && (
                <Button
                  variant="secondary"
                  size="sm"
                  icon={Trash2}
                  onClick={(e) => handleRemove(e, index)}
                  className="absolute top-2 right-2 !p-1.5 text-red-600 hover:text-red-700"
                >
                  <span className="sr-only">Remove</span>
                </Button>
              )}
              
              <div className="aspect-[4/3] bg-white rounded-lg overflow-hidden mb-4">
                <img
                  src={image}
                  alt={`Floor plan for ${plan.area}`}
                  className="w-full h-full object-contain"
                />
              </div>

              <div className="space-y-2">
                <div>
                  <h3 className="font-medium text-gray-900">{plan.area}</h3>
                  <p className="text-sm text-gray-600">Dimensions: {plan.width}' × {plan.length}'</p>
                  <p className="text-sm text-gray-600">Area: {sqft} sq ft</p>
                </div>

                {plan.notes && (
                  <div className="mt-2 pt-2 border-t border-gray-200">
                    <p className="text-sm text-gray-600 whitespace-pre-wrap">{plan.notes}</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FloorPlanSection;