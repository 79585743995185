export function cleanupPDFElement(element: HTMLElement): void {
  // Remove duplicate sections
  const sections = element.querySelectorAll('[data-section]');
  const seenSections = new Set<string>();
  sections.forEach(section => {
    const sectionId = section.getAttribute('data-section');
    if (sectionId) {
      if (seenSections.has(sectionId)) {
        section.remove();
      } else {
        seenSections.add(sectionId);
      }
    }
  });

  // Remove React-specific attributes
  element.querySelectorAll('[data-reactroot], [data-react-root], [data-reactid]')
    .forEach((el, index) => {
      if (index > 0) el.remove();
    });

  // Remove unnecessary elements
  element.querySelectorAll('.no-print, .page-break').forEach(el => el.remove());

  // Optimize styles
  element.style.margin = '0';
  element.style.padding = '0';
  element.style.border = 'none';
  element.style.borderRadius = '0';
  element.style.boxShadow = 'none';
  element.style.background = 'white';

  // Force color printing
  const elements = element.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i] as HTMLElement;
    el.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif';
    el.style.webkitPrintColorAdjust = 'exact';
    el.style.printColorAdjust = 'exact';
    el.style.colorAdjust = 'exact';
    el.style.forcedColorAdjust = 'none';

    // Ensure background colors are preserved
    if (el.classList.contains('bg-red-600')) {
      el.style.backgroundColor = '#dc2626';
      el.style.color = 'white';
    }
  }
}