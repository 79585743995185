import { supabase } from '../supabase';
import type { Database } from '../../types/supabase';

type Estimate = Database['public']['Tables']['estimates']['Row'];
type EstimateInsert = Database['public']['Tables']['estimates']['Insert'];
type EstimateUpdate = Database['public']['Tables']['estimates']['Update'];

export async function getEstimates(userId: string) {
  const { data, error } = await supabase
    .from('estimates')
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching estimates:', error);
    throw error;
  }

  return data;
}

export async function getEstimate(estimateId: string) {
  const { data, error } = await supabase
    .from('estimates')
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .eq('id', estimateId)
    .single();

  if (error) {
    console.error('Error fetching estimate:', error);
    throw error;
  }

  if (!data) {
    throw new Error('Estimate not found');
  }

  return {
    ...data,
    items: typeof data.items === 'string' ? JSON.parse(data.items) : data.items,
    measurements: typeof data.measurements === 'string' ? JSON.parse(data.measurements) : data.measurements
  };
}

export async function createEstimate(estimate: EstimateInsert) {
  const { data, error } = await supabase
    .from('estimates')
    .insert({
      ...estimate,
      items: typeof estimate.items === 'string' 
        ? estimate.items 
        : JSON.stringify(estimate.items)
    })
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .single();

  if (error) {
    console.error('Error creating estimate:', error);
    throw error;
  }

  return data;
}

export async function duplicateEstimate(estimateId: string) {
  try {
    // First get the original estimate with client data
    const { data: originalEstimate, error: fetchError } = await supabase
      .from('estimates')
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        )
      `)
      .eq('id', estimateId)
      .single();

    if (fetchError || !originalEstimate) {
      throw fetchError || new Error('Estimate not found');
    }

    // Prepare the new estimate data
    const { id, estimate_number, created_at, updated_at, client, ...estimateData } = originalEstimate;
    
    const newEstimate = {
      ...estimateData,
      title: `${estimateData.title} (Copy)`,
      status: 'draft' as const,
      created_at: new Date().toISOString()
    };

    // Create the duplicate
    const { data: duplicate, error: createError } = await supabase
      .from('estimates')
      .insert(newEstimate)
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        )
      `)
      .single();

    if (createError || !duplicate) {
      throw createError || new Error('Failed to create duplicate');
    }

    return duplicate;
  } catch (error) {
    console.error('Error duplicating estimate:', error);
    throw error;
  }
}

export async function updateEstimate(estimateId: string, estimate: EstimateUpdate) {
  const { client, ...updateData } = estimate;

  const { data, error } = await supabase
    .from('estimates')
    .update({
      ...updateData,
      items: typeof updateData.items === 'string'
        ? updateData.items
        : JSON.stringify(updateData.items)
    })
    .eq('id', estimateId)
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .single();

  if (error) {
    console.error('Error updating estimate:', error);
    throw error;
  }

  return data;
}

export async function updateEstimateStatus(
  estimateId: string, 
  status: 'draft' | 'pending' | 'approved' | 'rejected'
) {
  const { data, error } = await supabase
    .from('estimates')
    .update({ status })
    .eq('id', estimateId)
    .select(`
      *,
      client:clients (
        id,
        name,
        company,
        email,
        phone
      )
    `)
    .single();

  if (error) {
    console.error('Error updating estimate status:', error);
    throw error;
  }

  return data;
}

export async function deleteEstimate(estimateId: string): Promise<boolean> {
  try {
    // First update any invoices that reference this estimate to remove the reference
    const { error: updateError } = await supabase
      .from('invoices')
      .update({ estimate_id: null })
      .eq('estimate_id', estimateId);

    if (updateError) {
      console.error('Error updating invoice references:', updateError);
      throw updateError;
    }

    // Now delete the estimate
    const { error: deleteError } = await supabase
      .from('estimates')
      .delete()
      .eq('id', estimateId);

    if (deleteError) {
      console.error('Error deleting estimate:', deleteError);
      throw deleteError;
    }

    return true;
  } catch (error) {
    console.error('Error in deleteEstimate:', error);
    throw error;
  }
}

export async function createInvoiceFromEstimate(estimateId: string) {
  try {
    const { data: estimate, error: estimateError } = await supabase
      .from('estimates')
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        )
      `)
      .eq('id', estimateId)
      .single();

    if (estimateError || !estimate) {
      throw new Error('Failed to fetch estimate');
    }

    const invoiceData = {
      user_id: estimate.user_id,
      client_id: estimate.client_id,
      estimate_id: estimate.id,
      title: estimate.title,
      description: estimate.description,
      items: estimate.items,
      subtotal: estimate.subtotal,
      tax_rate: estimate.tax_rate,
      tax_amount: estimate.tax_amount,
      discount: estimate.discount,
      total: estimate.total,
      notes: estimate.notes,
      terms: estimate.terms,
      measurements: estimate.measurements,
      due_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // 30 days from now
      status: 'draft'
    };

    const { data: invoice, error: createError } = await supabase
      .from('invoices')
      .insert(invoiceData)
      .select(`
        *,
        client:clients (
          id,
          name,
          company,
          email,
          phone
        ),
        estimate:estimates (
          id,
          estimate_number
        )
      `)
      .single();

    if (createError || !invoice) {
      throw createError || new Error('Failed to create invoice');
    }

    return invoice;
  } catch (error) {
    console.error('Error creating invoice from estimate:', error);
    throw error;
  }
}